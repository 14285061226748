export enum LegalFormCategoryEnum {
  NaturalPersonCarryingOutAnAgriculturalActivity = 'NaturalPersonCarryingOutAnAgriculturalActivity',
  SoleTrader = 'SoleTrader',
  CivilLawPartnership = 'CivilLawPartnership',
  LimitedLiabilityCompany = 'LimitedLiabilityCompany',
  JoinStockCompany = 'JoinStockCompany',
  GeneralPartnership = 'GeneralPartnership',
  Partnership = 'Partnership',
  LimitedPartnership = 'LimitedPartnership',
  LimitedJointStockPartnership = 'LimitedJointStockPartnership'
}

export function numberToLegaLFormEnum(legalForm: number) {
  switch (legalForm) {
    case 1:
      return LegalFormCategoryEnum.NaturalPersonCarryingOutAnAgriculturalActivity;
    case 2:
      return LegalFormCategoryEnum.SoleTrader;
    case 3:
      return LegalFormCategoryEnum.CivilLawPartnership;
    case 4:
      return LegalFormCategoryEnum.LimitedLiabilityCompany;
    case 5:
      return LegalFormCategoryEnum.JoinStockCompany;
    case 6:
      return LegalFormCategoryEnum.GeneralPartnership;
    case 7:
      return LegalFormCategoryEnum.Partnership;
    case 8:
      return LegalFormCategoryEnum.LimitedPartnership;
    case 9:
      return LegalFormCategoryEnum.LimitedJointStockPartnership;
    default:
      return null;
  }
}

export function enumToLegalFormNumber(legalForm: string): number {
  switch (legalForm) {
    case LegalFormCategoryEnum.NaturalPersonCarryingOutAnAgriculturalActivity:
      return 1;
    case LegalFormCategoryEnum.SoleTrader:
      return 2;
    case LegalFormCategoryEnum.CivilLawPartnership:
      return 3;
    case LegalFormCategoryEnum.LimitedLiabilityCompany:
      return 4;
    case LegalFormCategoryEnum.JoinStockCompany:
      return 5;
    case LegalFormCategoryEnum.GeneralPartnership:
      return 6;
    case LegalFormCategoryEnum.Partnership:
      return 7;
    case LegalFormCategoryEnum.LimitedPartnership:
      return 8;
    case LegalFormCategoryEnum.LimitedJointStockPartnership:
      return 9;
    default:
      return null;
  }
}
