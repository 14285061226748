export enum SoilAnalysisAgronomicCategory {
  VeryLight = 'VeryLight',
  Light = 'Light',
  Average = 'Average',
  Heavy = 'Heavy',
  Organic = 'Organic',
  CarbonateLight = 'CarbonateLight',
  CarbonateAverage = 'CarbonateAverage',
  CarbonateHeavy = 'CarbonateHeavy'
}
