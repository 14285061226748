export enum WeatherStationMeasurementTypes {
  AirTemperature,
  AirHumidity,
  SoilTemperature,
  SoilHumidityLevel1,
  SoilHumidityLevel2,
  SoilHumidityLevel3,
  WindSpeed,
  WindDirection,
  RainCount,
  SunLevel,
  AirPressure,
  BatteryLevel,
  BatteryPercentage
}
