import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-primary-button',
  templateUrl: './primary-button.component.html'
})
export class PrimaryButtonComponent {
  @Input() labelTranslationKey: string;
  @Input() icon = 'ph ph-plus';
  @Input() isVisible = true;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  public click(): void {
    this.onClick.emit();
  }
}
