import { Component, Input } from '@angular/core';

import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html'
})
export class InputCheckboxComponent extends InputBaseComponent {
  @Input() public labelHtml: string;
  @Input() public checkboxId: string = null;
}
