import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'addPlus' })
export class AddPlusPipe implements PipeTransform {
  transform(value: string): string | null {
    if (parseFloat(value) >= 0) {
      return `+ ${value}`;
    }

    return value;
  }
}
