import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigurationService } from '../../../configuration/app-configuration.service';
import { DictionaryServiceBase } from '../common/dictionary-service-base';
import { DictionaryType } from '../../dictionary-type.enum';
import { DictionaryMode } from '../../dictionary-mode.enum';
import { IAgrofagTypeDictionaryModel } from './agrofag-type-dictionary.model';

@Injectable()
export class AgrofagTypeDictionaryService extends DictionaryServiceBase<IAgrofagTypeDictionaryModel[]> {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigurationService
  ) {
    super();
  }

  type: DictionaryType = DictionaryType.Agrofag;
  mode: DictionaryMode = DictionaryMode.Static;

  loadData(): Observable<IAgrofagTypeDictionaryModel[]> {
    const url = `${this.config.configuration.api_url}/api/v1/agrofags`;

    return this.httpClient.get<IAgrofagTypeDictionaryModel[]>(url).pipe(
      map(result =>
        result.map(x => {
          x.dictionaryType = DictionaryType[DictionaryType.Agrofag];
          x.codeName = x.agrofag;
          return x;
        })
      )
    );
  }
}
