import { Inject, InjectionToken, Injectable } from '@angular/core';

/* Create a new injection token for injecting the window into a component. */
export const WINDOW = new InjectionToken('WindowToken');

/** Based on https://brianflove.com/2018/01/11/angular-window-provider/ */

/* Define abstract class for obtaining reference to the global window object. */
export abstract class WindowRef {
  get nativeWindow(): Window | object {
    throw new Error('Not implemented.');
  }
}

/* Define class that implements the abstract class and returns the native window object. */
@Injectable()
export class BrowserWindowRef extends WindowRef {
  constructor() {
    super();
  }

  get nativeWindow(): Window | object {
    return window;
  }
}

@Injectable()
export class WindowService {
  constructor(@Inject(WINDOW) private window: Window) {}

  public refreshPage() {
    this.window.location.reload();
  }
}

@Injectable()
export class WindowPrintService {
  constructor(@Inject(WINDOW) private window: Window) {}

  public printPage() {
    this.window.print();
  }
}

@Injectable()
export class WindowScrollService {
  constructor(@Inject(WINDOW) private window: Window) {}
  public smoothScroolToTop(pixelsToScrollOnInterval: number, intervalTimeInMiliseconds: number) {
    const scrollToTop = this.window.setInterval(() => {
      const pos = this.window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - pixelsToScrollOnInterval);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, intervalTimeInMiliseconds);
  }
}
