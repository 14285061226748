import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { SpinnerDirective } from './spinner.directive';

@NgModule({
  declarations: [LoadingSpinnerComponent, SpinnerDirective],
  imports: [CommonModule],
  exports: [SpinnerDirective, LoadingSpinnerComponent]
})
export class SpinnerModule {}
