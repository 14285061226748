export enum CultivationPotatoPurposeType {
  ToEat = 'ToEat',
  ForChips = 'ForChips',
  ForFries = 'ForFries',
  ForStarch = 'ForStarch'
}

export enum CultivationPotatoDirectionOfUse {
  Edible = 'Edible',
  Fry = 'Fry',
  Chips = 'Chips',
  Starchy = 'Starchy'
}
