export enum FarmAuditSectionType {
  Waste = 'Waste',
  DDD = 'DDD',
  Transport = 'Transport',
  Production = 'Production',
  General = 'General',
  HealthAndSafety = 'HealthAndSafety',
  GlobalGAP = 'GlobalGAP',
  Buildings = 'Buildings',
  Recommendations = 'Recommendations'
}
