import { Component } from '@angular/core';

import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./../input.base.scss']
})
export class InputPasswordComponent extends InputBaseComponent {}
