import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStoreService } from './services/auth-store.service';

@Injectable()
export class IdpAuthGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private authStore: AuthStoreService
  ) {}

  canActivate(): boolean | Promise<boolean> | Observable<boolean> {
    return this.getAuthGuardResult();
  }

  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    return this.getAuthGuardResult();
  }

  private getAuthGuardResult(): boolean {
    const isUserLoggedIn = this.authStore.isAuthenticated;

    if (!isUserLoggedIn) {
      this.router.navigate(['auth/login']);
    }

    return isUserLoggedIn;
  }
}
