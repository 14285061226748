import { InjectionToken, Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { DictionaryTypeReturnType } from './dictionary-type-return-type.interface';
import { IDictionaryService } from './dictionary-service.interface';
import { DictionaryMode } from './dictionary-mode.enum';
import { DictionaryType } from './dictionary-type.enum';
import { DictionaryDropdownItem } from './dictionary-dropdown-item.interface';

export const DICTIONARIES_SERVICES = new InjectionToken<IDictionaryService[]>('DICTIONARIES_SERVICES');

@Injectable()
export class DictionariesService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cachedDictionaries: { [name: string]: Observable<any[]> } = {};

  constructor(
    @Inject(DICTIONARIES_SERVICES)
    private dictionariesServices: IDictionaryService[]
  ) {
    this.dictionariesServices.forEach(dictionaryService => {
      if (dictionaryService.mode === DictionaryMode.Static) {
        this.cachedDictionaries[dictionaryService.type] = dictionaryService.loadData().pipe(shareReplay());
      } else {
        this.cachedDictionaries[dictionaryService.type] = dictionaryService.loadData().pipe();
      }
    });
  }

  getItems<K extends DictionaryType>(key: K): DictionaryTypeReturnType[K] {
    return this.cachedDictionaries[key];
  }

  getDropdownItems<K extends DictionaryType>(key: K): Observable<DictionaryDropdownItem[]> {
    const mapItem = this.dictionariesServices.find(x => x.type === key).mapItem;

    return this.cachedDictionaries[key].pipe(map(x => x.map(mapItem)));
  }
}
