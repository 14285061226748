import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-danger-button',
  templateUrl: './danger-button.component.html'
})
export class DangerButtonComponent {
  @Input() labelTranslationKey = 'APPLICATION.FORM.delete';
  @Input() icon = 'ph ph-trash';
  @Input() isVisible = true;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  public click(): void {
    this.onClick.emit();
  }
}
