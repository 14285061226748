import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

@Directive({
  selector: '[appSpinner]'
})
export class SpinnerDirective implements OnChanges, OnDestroy {
  @Input('appSpinner') show;

  @HostBinding('class.position-relative')
  elementClass = true;

  private spinnerComponentRef: ComponentRef<LoadingSpinnerComponent>;

  constructor(
    private readonly vcRef: ViewContainerRef,
    private readonly cfResolver: ComponentFactoryResolver
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.show) {
      if (changes.show.currentValue) {
        this.createSpinner();
      } else {
        this.destroySpinner();
      }
    }
  }

  ngOnDestroy() {
    this.destroySpinner();
  }

  private createSpinner() {
    const spinnerComponentFactory = this.cfResolver.resolveComponentFactory(LoadingSpinnerComponent);
    this.spinnerComponentRef = this.vcRef.createComponent(spinnerComponentFactory);
  }

  private destroySpinner() {
    if (this.spinnerComponentRef) {
      this.spinnerComponentRef.destroy();
    }
  }
}
