import { Injectable } from '@angular/core';
import { DictionaryType } from '../../dictionary-type.enum';
import { DictionaryMode } from '../../dictionary-mode.enum';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigurationService } from '../../../configuration/app-configuration.service';
import { DictionaryServiceBase } from '../common/dictionary-service-base';
import { IBuildingTypeDictionaryModel } from './building-type-dictionary.model';

@Injectable()
export class BuildingTypeDictionaryService extends DictionaryServiceBase<IBuildingTypeDictionaryModel[]> {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigurationService
  ) {
    super();
  }

  type: DictionaryType = DictionaryType.BuildingType;
  mode: DictionaryMode = DictionaryMode.Static;

  loadData(): Observable<IBuildingTypeDictionaryModel[]> {
    const url = `${this.config.configuration.api_url}/api/definitions/buildingtypes`;
    return this.httpClient.get<IBuildingTypeDictionaryModel[]>(url);
  }
}
