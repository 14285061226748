import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'airHumidity' })
export class AirHumidityPipe extends DecimalPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  transform(value: null, digitsInfo?: string, locale?: string): null;
  transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  transform(value: number | null, locale?: string): string | null {
    if (value == null) {
      return '-';
    }

    return super.transform(value, '1.2-2', locale) + ' %';
  }
}
