import { Injectable } from '@angular/core';

import { from, Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';

import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { BlobUtilService } from 'farmcloud-core';

import { IImageOptions } from '../models/image-options';

@Injectable()
export class ImageCompressionLogicService {
  private readonly HUNDRED_VALUE = 100;

  constructor(
    private imageCompress: NgxImageCompressService,
    private blobUtilsService: BlobUtilService
  ) {}

  public compressImageToRatio(imageToCompress: File, options: IImageOptions): Observable<File> {
    return this.getImageResolution(imageToCompress).pipe(
      map((res: IImageOptions) => {
        return this.calculateRatio(res, options);
      }),
      flatMap(ratio => {
        return this.getBase64FromBlob(imageToCompress).pipe(
          flatMap(result => {
            return this.compressImage(result, ratio, options.quality);
          })
        );
      }),
      map(imageResult => {
        return this.blobUtilsService.blobToFile(this.blobUtilsService.dataUrlToBlob(imageResult).blob, imageToCompress.name);
      }),
      catchError(error => {
        console.error(error);
        return of(error);
      })
    );
  }

  private getBase64FromBlob(imageToCompress: File) {
    return from(
      this.blobUtilsService
        .blobToBase64(imageToCompress)
        .then(res => {
          return res;
        })
        .catch(error => {
          console.error('Blob to base64 convertion error:' + error);
          return error;
        })
    );
  }

  private compressImage(imageResult, ratio, quality): Observable<string> {
    return from(
      this.imageCompress
        .compressFile(imageResult, DOC_ORIENTATION.Default, ratio, quality)
        .then(res => {
          return res;
        })
        .catch(error => {
          console.error('Image compression error:' + error);
          return error;
        })
    );
  }

  private calculateRatio(res: IImageOptions, destRes: IImageOptions): number {
    let compressRatio = this.HUNDRED_VALUE;
    if (res.height >= res.width) {
      if (res.height > destRes.height) {
        compressRatio = this.HUNDRED_VALUE * (destRes.height / res.height);
      }
    } else {
      if (res.width > destRes.width) {
        compressRatio = this.HUNDRED_VALUE * (destRes.width / res.width);
      }
    }
    return compressRatio;
  }

  private getImageResolution(image: File): Observable<IImageOptions> {
    const file = image;
    const img = new Image();
    return new Observable(observer => {
      img.onload = () => {
        const resolution: IImageOptions = {
          width: img.width,
          height: img.height,
          quality: null
        };
        observer.next(resolution);
      };
      img.src = URL.createObjectURL(file);
    });
  }
}
