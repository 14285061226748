import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ngbd-modal-confirm',
  templateUrl: './ngbd-modal-confirm.component.html',
  styleUrls: ['./ngbd-modal-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgbdModalConfirmComponent {
  @Input() titleTranslateKey: string;
  @Input() messageTranslateKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() values: any[];

  constructor(public modal: NgbActiveModal) {}
}
