import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultValue'
})
export class DefaultValuePipe implements PipeTransform {
  transform(value: undefined | null, defaultValue = '-'): undefined | null | string {
    return value === null || value === undefined ? defaultValue : value;
  }
}
