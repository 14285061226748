import { NgModule } from '@angular/core';
import { AppInitService } from './app-init.service';

export function initAppConfiguration(appLoadService: AppInitService) {
  const fun = () => appLoadService.init();
  return fun;
}

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class ConfigurationModule {}
