export enum State {
  Dolnoslaskie = 'Dolnoslaskie',
  KujawskoPomorskie = 'KujawskoPomorskie',
  Lubelskie = 'Lubelskie',
  Lubuskie = 'Lubuskie',
  Lodzkie = 'Lodzkie',
  Malopolskie = 'Malopolskie',
  Mazowieckie = 'Mazowieckie',
  Opolskie = 'Opolskie',
  Podkarpackie = 'Podkarpackie',
  Podlaskie = 'Podlaskie',
  Pomorskie = 'Pomorskie',
  Slaskie = 'Slaskie',
  Swietokrzyskie = 'Swietokrzyskie',
  WarminskoMazurskie = 'WarminskoMazurskie',
  Wielkopolskie = 'Wielkopolskie',
  Zachodniopomorskie = 'Zachodniopomorskie'
}
