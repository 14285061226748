import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'applicationDictionary'
})
export class ApplicationDictionaryPipe implements PipeTransform {
  constructor(private readonly translationService: TranslateService) {}

  transform(value: string): string {
    return this.translationService.instant('APPLICATION.DICTIONARIES.' + value);
  }
}
