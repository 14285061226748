import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dictionary'
})
export class DictionaryPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    if (value) {
      return this.translateService.instant(`APPLICATION.DICTIONARIES.${value}`);
    }

    return '';
  }
}
