import { Pipe, PipeTransform } from '@angular/core';

// TODO: Should be moved to a separate module
@Pipe({
  name: 'enumTranslateKey'
})
export class EnumTranslateKeyPipe implements PipeTransform {
  transform(value: string, prefix: string): string {
    if (value) {
      return `${prefix}.${value.charAt(0).toLocaleLowerCase() + value.slice(1)}`;
    }
    return '';
  }
}
