import { Pipe, PipeTransform } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';

@Pipe({
  name: 'farmPortalWeatherStationsReplaceLink'
})
export class FarmPortalWeatherStationsReplaceLinkPipe implements PipeTransform {
  constructor(
    private router: Router,
    private serializer: UrlSerializer
  ) {}

  transform(value: string): string {
    const regex = /\[link:farmportal:weather-detail:(.*):'(.*)'\]/g;

    if (regex.test(value)) {
      const url = this.prepareWeatherDetailUrlPrefix();
      return value.replace(/\[link:farmportal:weather-detail:(.*):'(.*)'\]/g, `<a href="${url}/$1">$2</a>`);
    }

    return value;
  }

  private prepareWeatherDetailUrlPrefix(): string {
    const tree = this.router.createUrlTree(['weatherstations']);
    return this.serializer.serialize(tree);
  }
}
