import { Injectable } from '@angular/core';
import { DictionaryType } from '../../dictionary-type.enum';
import { DictionaryMode } from '../../dictionary-mode.enum';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITreatmentTypeDictionaryModel } from './treatment-type-dictionary.model';
import { AppConfigurationService } from '../../../configuration/app-configuration.service';
import { DictionaryServiceBase } from '../common/dictionary-service-base';

@Injectable()
export class TreatmentTypeDictionaryService extends DictionaryServiceBase<ITreatmentTypeDictionaryModel[]> {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigurationService
  ) {
    super();
  }

  type: DictionaryType = DictionaryType.TreatmentType;
  mode: DictionaryMode = DictionaryMode.Static;

  loadData(): Observable<ITreatmentTypeDictionaryModel[]> {
    const url = `${this.config.configuration.api_url}/api/definitions/treatments`;
    return this.httpClient.get<ITreatmentTypeDictionaryModel[]>(url);
  }
}
