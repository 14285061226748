export enum FertilizerBoronUnit { // Bor
  B = 'B'
}

export enum FertilizerCalciumUnit { // Wapń
  CA = 'CA',
  CAO = 'CAO',
  CACO3 = 'CACO3'
}

export enum FertilizerCopperUnit { // Miedź
  CU = 'CU'
}

export enum FertilizerCarbonUnit { // Węgiel
  C = 'C'
}

export enum FertilizerIronUnit { // Żelazo
  FE = 'FE'
}

export enum FertilizerMagnesiumUnit { // Magnez
  MG = 'MG',
  MGO = 'MGO'
}

export enum FertilizerManganeseUnit { // Mangan
  MN = 'MN'
}

export enum FertilizerMolybdenumUnit { // Molibden
  MO = 'MO'
}

export enum FertilizerNickelUnit { // Nikiel
  NI = 'NI'
}

export enum FertilizerNitrogenUnit { // Azot
  N = 'N'
}

export enum FertilizerPotassiumUnit { // Potas
  K = 'K',
  K2O = 'K2O'
}

export enum FertilizerPhosphorusUnit { // Fosfor
  P = 'P',
  P2O5 = 'P2O5'
}

export enum FertilizerSeleniumUnit { // Selen
  SE = 'SE'
}

export enum FertilizerSodiumUnit { // Sód
  NA = 'NA',
  NA2O = 'NA2O'
}

export enum FertilizerSiliconUnit { // Krzem
  SI = 'SI',
  SIO2 = 'SIO2'
}

export enum FertilizerSulfurUnit { // Siarka
  S = 'S',
  SO3 = 'SO3'
}

export enum FertilizerTitaniumUnit { // Tytan
  TI = 'TI'
}

export enum FertilizerVanadiumUnit { // Wanad
  V = 'V'
}

export enum FertilizerZincUnit { // Cynk
  ZN = 'ZN'
}
