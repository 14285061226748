import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule, StaticClassProvider } from '@angular/core';
import { TranslateModule as NgxTranslateModule, TranslateService } from '@ngx-translate/core';
import { EnumTranslateKeyPipe } from './enum-translate-key.pipe';
import { DictionaryTranslateKeyPipe } from './dictionary-translate-key.pipe';
import { DictionaryPipe } from './dictionary.pipe';

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super();
  }

  toString() {
    return this.service.currentLang;
  }
}

export const LocaleProvider: StaticClassProvider = {
  provide: LOCALE_ID,
  useClass: DynamicLocaleId,
  deps: [TranslateService]
};

@NgModule({
  declarations: [EnumTranslateKeyPipe, DictionaryTranslateKeyPipe, DictionaryPipe],
  imports: [CommonModule],
  exports: [NgxTranslateModule, EnumTranslateKeyPipe, DictionaryTranslateKeyPipe, DictionaryPipe],
  providers: [LocaleProvider]
})
export class TranslationModule {}
