import { Component, OnInit } from '@angular/core';
import { ROUTES } from '@farm-portal/app-routing.config';
import { AUTH_PARTNERS } from '@farm-portal/core/auth/models/auth-partners.const';
import { AuthStoreService } from '@farm-portal/core/auth/services/auth-store.service';
import { AuthService } from '@farm-portal/core/auth/services/auth.service';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
import { IUserOrganizationResellerDto } from '@farm-portal/core/auth/user-organization-reseller.dto';
import { IUserOrganizationDto } from '@farm-portal/core/auth/user-organization.dto';
import { RouteGroup } from '@farm-portal/core/layout/sidebar/sidebar.metadata';
import { MessageService } from '@farm-portal/core/layout/navbar/message-unread-notification/message.service';
import { NotificationService } from '@farm-portal/core/layout/navbar/navbar-notifications/notification.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
  providers: [MessageService, NotificationService]
})
export class FullLayoutComponent implements OnInit {
  iscollapsed = false;

  get groupClaims(): string[] {
    return this.authStore.getUserClaims;
  }

  constructor(
    private readonly authService: AuthService,
    private readonly authStore: AuthStoreService,
    private readonly userContextService: UserContextService
  ) {}

  public routes: RouteGroup[] = ROUTES;
  public user: string;

  reseller: IUserOrganizationResellerDto;

  public ngOnInit() {
    this.user = this.authStore.getUserFullName;
    this.userContextService.currentUserOrganization$.subscribe((x: IUserOrganizationDto) => {
      this.reseller = x != null ? x.reseller : null;
    });
  }

  public logout() {
    const partner = AUTH_PARTNERS.find(partner => this.hasAccess(partner.permissionComponent));
    const partnerId = partner ? partner.partnerId : undefined;
    this.authService.logout();
    this.userContextService.clean();
    return this.authService.navigateToLoginPage(partnerId);
  }

  hasAccess(componentKey: string): boolean {
    return this.groupClaims.some(claim => {
      return claim.startsWith(componentKey);
    });
  }
}
