export enum GroundCategory {
  I = 'I',
  II = 'II',
  IIIa = 'IIIa',
  IIIb = 'IIIb',
  IV = 'IV',
  IVa = 'IVa',
  IVb = 'IVb',
  V = 'V',
  VI = 'VI'
}
