import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class InputMapService {
  constructor(private readonly translateService: TranslateService) {}

  private previousInfoWindow = new BehaviorSubject<google.maps.InfoWindow>(null);

  get currentInfoWindow(): google.maps.InfoWindow {
    return this.previousInfoWindow.getValue();
  }

  public updatePreviousInfoWindow(infoWindow: google.maps.InfoWindow) {
    this.previousInfoWindow.next(infoWindow);
  }

  public closeCurrentInfoWindow() {
    if (this.currentInfoWindow) {
      this.currentInfoWindow.close();
      this.previousInfoWindow.next(null);
    }
  }

  public buildRemoveInfoWindow(id: string): string {
    return `<div>
                <div style="display:flex; flex-direction:column; gap:10px; justify-content: center">
                    <button class="btn btn-negative" id="${id}_Remove">${this.translateService.instant('APPLICATION.FORM.delete')}</button>
                </div>
            </div>`;
  }

  public setOnClickMarkerListener(map: google.maps.Map, marker: google.maps.Marker, infoWindow: google.maps.InfoWindow) {
    marker.addListener('click', () => {
      const currentInfoWindow = this.currentInfoWindow;

      if (currentInfoWindow != null) {
        this.closeCurrentInfoWindow();
      }

      if (infoWindow != currentInfoWindow) {
        this.updatePreviousInfoWindow(infoWindow);
        infoWindow.setPosition(marker.getPosition());
        infoWindow.open(map);
      }
    });
  }

  public setOnClickPolygonListener(map: google.maps.Map, polygon: google.maps.Polygon, infoWindow: google.maps.InfoWindow) {
    polygon.addListener('click', () => {
      const bounds = new google.maps.LatLngBounds();
      polygon.getPath().forEach(x => {
        bounds.extend(x);
      });
      const currentInfoWindow = this.currentInfoWindow;
      if (currentInfoWindow != null) {
        this.closeCurrentInfoWindow();
      }

      if (infoWindow != currentInfoWindow) {
        this.updatePreviousInfoWindow(infoWindow);
        infoWindow.setPosition(bounds.getCenter());
        infoWindow.open(map);
      }
    });
  }
}
