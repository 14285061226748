import { Injectable } from '@angular/core';
import { Month } from '../enums/month.enum';
import { format } from 'date-fns';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class DateUtilService {
  fromTimestampToDate(timestamp: number): Date {
    const multiplier = 1000;
    return new Date(timestamp * multiplier);
  }

  fromTimestampToString(timestamp: number): string {
    const multiplier = 1000;
    const date = new Date(timestamp * multiplier);
    return this.fromDateToSting(date);
  }

  fromDateToTimestamp(date: Date): number | null {
    if (date == null) {
      return null;
    }

    const multiplier = 1000;
    return date.getTime() / multiplier;
  }

  fromDateToSting(date: Date): string {
    if (date == null) {
      return null;
    }

    return format(date, 'yyyy-MM-dd');
  }

  fromStringToTimestamp(date: string): number | null {
    if (date == null) {
      return null;
    }
    const dateValue = new Date(date);
    return this.fromDateToTimestamp(dateValue);
  }

  fromStringToDate(date: string): Date | null {
    return date == null ? null : new Date(date);
  }

  fromISOStringToDateOnlyString(isoStringDate: string): string {
    if (isoStringDate == null) {
      return null;
    }
    const dateTime = DateTime.fromISO(isoStringDate);
    const date = new Date(dateTime.year, dateTime.month - 1, dateTime.day);
    return this.fromDateToSting(date);
  }

  getMaxDays(month: Month): number {
    const day31 = 31;
    const day29 = 29;
    const day30 = 30;

    switch (month) {
      case Month.January:
        return day31;
      case Month.February:
        return day29;
      case Month.March:
        return day31;
      case Month.April:
        return day30;
      case Month.May:
        return day31;
      case Month.June:
        return day30;
      case Month.July:
        return day31;
      case Month.August:
        return day31;
      case Month.September:
        return day30;
      case Month.October:
        return day31;
      case Month.November:
        return day30;
      case Month.December:
        return day31;
    }
  }

  getMonthIndex(month: Month): number {
    switch (month) {
      case Month.January:
        return 1;
      case Month.February:
        return 2;
      case Month.March:
        return 3;
      case Month.April:
        return 4;
      case Month.May:
        return 5;
      case Month.June:
        return 6;
      case Month.July:
        return 7;
      case Month.August:
        return 8;
      case Month.September:
        return 9;
      case Month.October:
        return 10;
      case Month.November:
        return 11;
      case Month.December:
        return 12;
    }
  }

  getMonth(monthIndex: number): Month {
    switch (monthIndex) {
      case 1:
        return Month.January;
      case 2:
        return Month.February;
      case 3:
        return Month.March;
      case 4:
        return Month.April;
      case 5:
        return Month.May;
      case 6:
        return Month.June;
      case 7:
        return Month.July;
      case 8:
        return Month.August;
      case 9:
        return Month.September;
      case 10:
        return Month.October;
      case 11:
        return Month.November;
      case 12:
        return Month.December;
    }
  }
}
