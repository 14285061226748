import { HttpParams } from '@angular/common/http';
import { RequestList } from './models/request-list.model';

const CONTAINS_OPERATOR = '=*';

export function prepareListUrl<T>(url: string, requestList: RequestList<T>) {
  return prepareListUrlWithFilter(url, requestList, () => []);
}

export function prepareListUrlWithFilter<T>(url: string, requestList: RequestList<T>, func: (T) => string[] = null) {
  let params: string[] = [];

  if (requestList.pageSize != null) {
    params.push(`PageSize=${requestList.pageSize}`);
  }

  if (requestList.page != null) {
    params.push(`Page=${requestList.page + 1}`); // back-end start page from 1, front-end from 0.
  }

  if (requestList.orderBy) {
    params.push(`OrderBy=${requestList.orderBy}`);
  }

  if (requestList.sortOrder != null) {
    params.push(`SortOrder=${requestList.sortOrder}`);
  }

  if (func != null && requestList.filter != null) {
    params = [...params, ...func(requestList.filter)];
  }

  if (params.length === 0) {
    return url;
  }

  return `${url}?${params.join('&')}`;
}

export function prepareQueryGridifyParams<T>(httpParams: HttpParams, requestList: RequestList<T>): HttpParams {
  let params = httpParams;
  params = preparePaginationQueryParams(params, requestList.page, requestList.pageSize);
  params = prepareOrderingQueryGridifyParams(params, requestList.orderBy, requestList.sortOrder);
  params = prepareFilterQueryGridifyParams(params, requestList.filter);

  return params;
}

export function prepareQueryArrayParams<T>(httpParams: HttpParams, requestList: RequestList<T>): HttpParams {
  let params = httpParams;
  params = preparePaginationQueryParams(params, requestList.page, requestList.pageSize);
  params = prepareOrderingQueryParams(params, requestList.orderBy, requestList.sortOrder);

  if (requestList.filter) {
    Object.keys(requestList.filter).forEach(key => {
      if (requestList.filter[key] != null) {
        if (Array.isArray(requestList.filter[key])) {
          requestList.filter[key].forEach(item => {
            params = params.append(key, item);
          });
        } else {
          params = params.set(key, requestList.filter[key]);
        }
      }
    });
  }
  return params;
}

export function prepareQueryParams<T>(httpParams: HttpParams, requestList: RequestList<T>): HttpParams {
  let params = httpParams;
  params = preparePaginationQueryParams(params, requestList.page, requestList.pageSize, requestList.isNgbPagination);
  params = prepareOrderingQueryParams(params, requestList.orderBy, requestList.sortOrder);

  if (requestList.filter) {
    Object.keys(requestList.filter).forEach(key => {
      if (requestList.filter[key] != null) {
        let value = requestList.filter[key];
        if (instanceOfDate(value)) {
          value = value.toISOString();
        }

        params = params.set(key, value);
      }
    });
  }
  return params;
}

function preparePaginationQueryParams(httpParams: HttpParams, page?: number, pageSize?: number, isNgbPagination?: boolean): HttpParams {
  let params = httpParams;

  if (page != null) {
    params = params.set('page', String(page + (isNgbPagination ? 0 : 1))); // back-end start page from 1, cdk-pagination from 0 but NgbPagination from 1.
  }

  if (pageSize != null) {
    params = params.set('pageSize', String(pageSize));
  }

  return params;
}

function prepareOrderingQueryParams(httpParams: HttpParams, orderBy?: string, sortOrder?: string): HttpParams {
  let params = httpParams;

  if (orderBy) {
    params = params.set('orderBy', orderBy);
  }

  if (sortOrder != null) {
    params = params.set('sortOrder', sortOrder);
  }

  return params;
}

function prepareOrderingQueryGridifyParams(httpParams: HttpParams, orderBy?: string, sortOrder?: string): HttpParams {
  let params = httpParams;
  let order = '';

  if (orderBy) {
    order = orderBy;
  }
  if (sortOrder != null) {
    order = order + ' ' + sortOrder;
  }
  params = params.set('orderBy', order);

  return params;
}

function prepareFilterQueryGridifyParams<T>(httpParams: HttpParams, filter: T): HttpParams {
  let params = httpParams;
  if (filter && Object.keys(filter).length > 0) {
    const queryFilter = Object.keys(filter)
      .filter(key => !!filter[key])
      .map(key => key + CONTAINS_OPERATOR + filter[key]);

    if (queryFilter.toString() !== '') {
      params = params.set('filterBy', queryFilter.toString());
    }
  }
  return params;
}

function instanceOfDate(object: unknown): object is Date {
  if (!object) {
    return false;
  }

  return object instanceof Date;
}
