import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  template: ''
})
export class InputBaseComponent {
  @Input() public inputControl: FormControl;
  @Input() public labelTranslateKey = '';
  @Input() public placeholderTranslateKey: string | null;

  public isInputControlInvalid(): boolean {
    return this.inputControl.errors && (this.inputControl.dirty || this.inputControl.touched);
  }

  public isInputControlRequired(): boolean {
    if (!this.inputControl) {
      throw new Error('inputControl is not defined');
    }

    if (this.inputControl.validator) {
      const validator = this.inputControl.validator({} as AbstractControl);

      return !!(validator && validator.required);
    }

    return false;
  }
}
