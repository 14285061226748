export enum ComplexOfAgriculturalSuitability {
  VeryGoodWheat = 'VeryGoodWheat',
  GoodWheat = 'GoodWheat',
  FaultyWheat = 'FaultyWheat',
  VeryGoodRye = 'VeryGoodRye',
  GoodRye = 'GoodRye',
  WeakRye = 'WeakRye',
  VeryWeakRye = 'VeryWeakRye',
  StrongGrainFodder = 'StrongGrainFodder',
  WeakGrainFodder = 'WeakGrainFodder',
  MountainWheat = 'MountainWheat',
  MountainGrain = 'MountainGrain',
  MountainOatPotato = 'MountainOatPotato',
  MountainOatFodder = 'MountainOatFodder',
  SoilUsedForGrasslands = 'SoilUsedForGrasslands',
  VeryGood = 'VeryGood',
  Good = 'Good',
  Average = 'Average',
  Weak = 'Weak',
  VeryWeak = 'VeryWeak'
}
