import { Component, Input } from '@angular/core';

import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./../input.base.scss']
})
export class InputTextareaComponent extends InputBaseComponent {
  @Input() public rows = 15;
}
