import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigurationService } from '../../../configuration/app-configuration.service';
import { DictionaryServiceBase } from '../common/dictionary-service-base';
import { DictionaryType } from '../../dictionary-type.enum';
import { DictionaryMode } from '../../dictionary-mode.enum';
import { IActiveSubstanceTypeDictionaryModel } from './active-substance-type-dictionary.model';

@Injectable()
export class ActiveSubstanceTypeDictionaryService extends DictionaryServiceBase<IActiveSubstanceTypeDictionaryModel[]> {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigurationService
  ) {
    super();
  }

  type: DictionaryType = DictionaryType.ActiveSubstanceType;
  mode: DictionaryMode = DictionaryMode.Static;

  loadData(): Observable<IActiveSubstanceTypeDictionaryModel[]> {
    const url = `${this.config.configuration.api_url}/api/v1/active_substances`;

    return this.httpClient.get<IActiveSubstanceTypeDictionaryModel[]>(url).pipe(
      map(result =>
        result.map(x => {
          x.dictionaryType = DictionaryType[DictionaryType.ActiveSubstanceType];
          x.codeName = x.name;
          return x;
        })
      )
    );
  }
}
