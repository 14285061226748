import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from '@farm-portal/core/layout';
import { SiteTitle } from './app-site-titles.enum';
import { IdpAuthGuard } from './core/auth/idp-auth.guard';
import { IdpOrganizationGuard } from './core/auth/idp-organization.guard';
import { LoadUserDataComponent } from './core/layout/load-user-data/load-user-data.component';
import { IdpRucipGuard } from '@farm-portal/core/auth/idp-rucip.guard';
import { NotFoundComponent } from '@farm-portal/notFound.component';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/authorization/authorization.module').then(m => m.AuthorizationModule)
  },
  {
    path: 'load-user-data',
    component: LoadUserDataComponent,
    canActivate: [IdpAuthGuard]
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'my-fields',
        pathMatch: 'full'
      },
      {
        path: 'contact',
        loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.contact
        }
      },
      {
        path: 'farm',
        loadChildren: () => import('./modules/farm/farm.module').then(m => m.FarmModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.farm
        }
      },
      {
        path: 'carbon-assistant',
        loadChildren: () => import('./modules/carbon-assistant/carbon-assistant.module').then(m => m.CarbonAssistantModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.carbonAssistant
        }
      },
      {
        path: 'contracts',
        loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.contracts
        }
      },
      {
        path: 'my-fields',
        loadChildren: () => import('./modules/ground/ground.module').then(m => m.GroundModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.myFields
        }
      },
      {
        path: 'cultivation-assistant',
        loadChildren: () => import('./modules/cultivation-assistant/cultivation-assistant.module').then(m => m.CultivationAssistantModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.cultivationAssistant
        }
      },
      {
        path: 'buildings',
        loadChildren: () => import('./modules/buildings/buildings.module').then(m => m.BuildingsModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.buildings
        }
      },
      {
        path: 'employees',
        loadChildren: () => import('./modules/employee/employee.module').then(m => m.EmployeeModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.employees
        }
      },
      {
        path: 'machines',
        loadChildren: () => import('./modules/machine/machine.module').then(m => m.MachineModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.machines
        }
      },
      {
        path: 'observations',
        loadChildren: () => import('./modules/observation/observation.module').then(m => m.ObservationModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.observations
        }
      },
      {
        path: 'rucip',
        loadChildren: () => import('./modules/rucip-observation/rucip.module').then(m => m.RucipModule),
        canLoad: [IdpAuthGuard, IdpRucipGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.rucip
        }
      },
      {
        path: 'fertilizer-stock-needs',
        loadChildren: () =>
          import('./modules/fertilizer-stock-needs/fertilizer-stock-needs.module').then(m => m.FertilizerStockNeedsModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.fertilizerStockNeeds
        }
      },
      {
        path: 'weatherstations',
        loadChildren: () => import('./modules/weather-station/weather-station.module').then(m => m.WeatherStationModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.weatherStations
        }
      },
      {
        path: `storageSensors`,
        loadChildren: () => import('./modules/storage-sensors/storage-sensors.module').then(m => m.StorageSensorsModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.storageSensors
        }
      },
      {
        path: `devices`,
        loadChildren: () => import('./modules/devices/devices.module').then(m => m.DevicesModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.devices
        }
      },
      {
        path: 'messages',
        loadChildren: () => import('./modules/message/message.module').then(m => m.MessageModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.messages
        }
      },
      {
        path: 'vehtel-monitoring',
        loadChildren: () => import('./modules/vehtel-monitoring/vehtel-monitoring.module').then(m => m.VehtelMonitoringModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.monitoring
        }
      },
      {
        path: 'report',
        loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard]
      },
      {
        path: 'fertilizers-db',
        loadChildren: () => import('./modules/fertilizer-db/fertilizer-db.module').then(m => m.FertilizerDbModule),
        data: {
          title: SiteTitle.fertilizersDb
        }
      },
      {
        path: 'pesticides',
        loadChildren: () => import('./modules/pesticide/pesticide.module').then(m => m.PesticideModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.pesticides
        }
      },
      {
        path: 'documents',
        loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [IdpAuthGuard, IdpOrganizationGuard],
        data: {
          title: SiteTitle.documents
        }
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.settings
        }
      },
      {
        path: 'stock-offers',
        loadChildren: () => import('./modules/stock-offer/stock-offer.module').then(m => m.StockOfferModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.stockOffers
        }
      },
      {
        path: 'suppliers',
        loadChildren: () => import('./modules/supplier/supplier.module').then(m => m.SupplierModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.suppliers
        }
      },
      {
        path: 'emission-calculations',
        loadChildren: () => import('./modules/emission-calculation/emission-calculation.module').then(m => m.EmissionCalculationModule),
        data: {
          title: SiteTitle.emissionCalculations
        }
      },
      {
        path: 'warehouses',
        loadChildren: () => import('./modules/warehouse/warehouse.module').then(m => m.WarehouseModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.warehousesProductStocks
        }
      },
      {
        path: 'soil-analysis',
        loadChildren: () => import('./modules/soil-analysis/soil-analysis.module').then(m => m.SoilAnalysisModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.soilAnalysis
        }
      },
      {
        path: 'satellite-images',
        loadChildren: () => import('./modules/satellite-images/satellite-images.module').then(m => m.SatelliteImagesModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.satelliteImages
        }
      },
      {
        path: 'treatments',
        loadChildren: () => import('./modules/treatments/treatments.module').then(m => m.TreatmentsModule),
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.treatments
        }
      }
    ],
    canActivateChild: [IdpOrganizationGuard]
  },
  {
    path: 'notFound',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
