export enum CertificateType {
  ISO = 'ISO',
  FSSC = 'FSSC',
  IFSFOOD = 'IFSFOOD',
  QAFP = 'QAFP',
  QMP = 'QMP',
  PQS = 'PQS',
  ICB = 'ICB',
  PTIR = 'PTIR',
  VCERT = 'VCERT',
  IP = 'IP',
  GLOBALGAP = 'GLOBALGAP',
  BRC = 'BRC',
  EKO = 'EKO',
  GRASP = 'GRASP',
  OTHER = 'OTHER'
}
