import { GeometryType } from '../enums';

export function getGeometryType(enumIndex: string | number) {
  switch (enumIndex) {
    case GeometryType.Point: {
      return 'Point';
    }
    case 'Point': {
      return 'Point';
    }
    case GeometryType.MultiPoint: {
      return 'MultiPoint';
    }
    case 'MultiPoint': {
      return 'MultiPoint';
    }
    case GeometryType.LineString: {
      return 'LineString';
    }
    case 'LineString': {
      return 'LineString';
    }
    case GeometryType.MultiLineString: {
      return 'MultiLineString';
    }
    case 'MultiLineString': {
      return 'MultiLineString';
    }
    case GeometryType.Polygon: {
      return 'Polygon';
    }
    case 'Polygon': {
      return 'Polygon';
    }
    case GeometryType.MultiPolygon: {
      return 'MultiPolygon';
    }
    case 'MultiPolygon': {
      return 'MultiPolygon';
    }
    case GeometryType.GeometryCollection: {
      return 'GeometryCollection';
    }
    case 'GeometryCollection': {
      return 'GeometryCollection';
    }
    default: {
      return 'Polygon';
    }
  }
}
