import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigurationService, ApiListResponse } from 'farmcloud-core';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
import { Observable } from 'rxjs';
import { IPartnerDto } from '../models/partner.dto';

@Injectable({
  providedIn: 'root'
})
export class SettingsCoreProxyService {
  private readonly baseApiUrl: string;

  constructor(
    config: AppConfigurationService,
    private userContextService: UserContextService,
    protected httpClient: HttpClient
  ) {
    this.baseApiUrl = `${config.configuration.api_url}/api/PartnerOrganization`;
  }

  getPartnerList(): Observable<ApiListResponse<IPartnerDto>> {
    const url = `${this.baseApiUrl}/GetAllPartners/${this.userContextService.currentOrganizationId}`;
    return this.httpClient.get<ApiListResponse<IPartnerDto>>(url);
  }

  addPartner(partnerId: string): Observable<void> {
    const url = `${this.baseApiUrl}/linkExistingOrganization`;
    const payload = {
      organizationId: partnerId,
      partnerOrganizationId: this.userContextService.currentOrganizationId
    };
    return this.httpClient.post<void>(url, payload);
  }

  removePartner(partnerId: string): Observable<void> {
    const url = `${this.baseApiUrl}/unlink`;
    const payload = {
      organizationId: partnerId,
      partnerOrganizationId: this.userContextService.currentOrganizationId
    };
    return this.httpClient.post<void>(url, payload);
  }
}
