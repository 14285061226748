import { Observable } from 'rxjs';
import { IDictionaryService } from '../../dictionary-service.interface';
import { DictionaryType } from '../../dictionary-type.enum';
import { DictionaryMode } from '../../dictionary-mode.enum';
import { DictionaryDropdownItem } from '../../dictionary-dropdown-item.interface';

export abstract class DictionaryServiceBase<T> implements IDictionaryService {
  type: DictionaryType;
  mode: DictionaryMode;

  loadData(): Observable<T> {
    throw new Error('Implement dictionary loading data');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapItem(item: any): DictionaryDropdownItem {
    return { value: item.codeName, label: item.codeName };
  }
}
