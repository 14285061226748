import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ImageModel } from 'farmcloud-core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GalleryModalService {
  private modalOpenSource = new BehaviorSubject<boolean>(false);
  private modalImagesSource = new BehaviorSubject<ImageModel[]>([]);

  public selectedImage$ = new BehaviorSubject<ImageModel | null>(null);
  public selectedIndex$ = new BehaviorSubject<number>(0);
  public modalOpen$ = this.modalOpenSource.asObservable();
  public modalImages$ = this.modalImagesSource.asObservable();
  public imagesCount$ = this.modalImages$.pipe(
    map(x => {
      if (!x) {
        return 0;
      }

      return x.length;
    })
  );

  public get isOpen() {
    return this.modalOpenSource.value;
  }

  public open(images: ImageModel[]) {
    if (images.length === 0) {
      return;
    }

    this.modalImagesSource.next(images);
    this.selectImage(0);
    this.modalOpenSource.next(true);
  }

  public close() {
    this.modalOpenSource.next(false);
  }

  public selectImage(i: number) {
    this.selectedIndex$.next(i);
    this.selectedImage$.next(this.modalImagesSource.value[i]);
  }

  public hasNextImage(): boolean {
    return this.selectedIndex$.value < this.modalImagesSource.value.length - 1;
  }

  public hasPrevImage(): boolean {
    return this.selectedIndex$.value > 0;
  }

  public Next() {
    if (!this.hasNextImage()) {
      return;
    }

    this.selectImage(this.selectedIndex$.value + 1);
  }

  public Prev() {
    if (!this.hasPrevImage()) {
      return;
    }

    this.selectImage(this.selectedIndex$.value - 1);
  }
}
