import { DatePipe } from '@angular/common';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'longDateTime'
})
export class LongDateTimePipe extends DatePipe {
  public static readonly DateFormat = 'dd.MM.yyyy HH:mm';

  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null {
    if (value === null) {
      return null;
    }
    return super.transform(value, LongDateTimePipe.DateFormat, timezone, locale);
  }
}
