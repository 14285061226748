export enum CultivationUsageDirectionEnum {
  Grain = 'Grain',
  Fodder = 'Fodder',
  Forage = 'Forage',
  Seed = 'Seed',
  Nursery = 'Nursery',
  Fruit = 'Fruit',
  Early = 'Early',
  Late = 'Late'
}
