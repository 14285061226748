import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PureMapComponent } from './components/pure-map/pure-map.component';
import { PureGoogleMapsServicesModule } from './pure-google-maps-services.module';

@NgModule({
  declarations: [PureMapComponent],
  imports: [CommonModule, PureGoogleMapsServicesModule],
  exports: [PureMapComponent]
})
export class PureGoogleMapsSharedModule {}
