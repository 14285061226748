import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-nav-item',
  templateUrl: './user-nav-item.component.html',
  styleUrls: ['./user-nav-item.component.scss']
})
export class UserNavItemComponent {
  @Output()
  logout: EventEmitter<void> = new EventEmitter();

  logoutClick(event) {
    this.logout.emit();
    event.stopPropagation();
  }
}
