import { Injectable } from '@angular/core';
import { FileModel } from 'farmcloud-core';
import { documentImageDataUrl } from '../image.utils';

@Injectable()
export class FileFormService {
  setImageThumbnailFromUrl(file: FileModel, allowedImageTypes: string[]) {
    if (file.mimeType && file.mimeType.includes('image')) {
      return { noImageFile: false, url: file.link };
    }

    if (file.mimeType == null || file.mimeType === undefined) {
      const fileExtension = new URL(file.link.split('?sv')[0]).pathname.split('.')[1];
      if (!allowedImageTypes.includes(fileExtension)) {
        return { noImageFile: true, url: documentImageDataUrl };
      } else {
        return { noImageFile: false, url: file.link };
      }
    }

    return { noImageFile: true, url: documentImageDataUrl };
  }

  setImageThumbnailFromFile(file: File, allowedImageTypes: string[]) {
    const extension = file.name.split('.')[1];

    if (!allowedImageTypes.includes(extension)) {
      return {
        file,
        url: documentImageDataUrl,
        noImageFile: true
      };
    } else {
      return {
        file,
        url: URL.createObjectURL(file),
        noImageFile: false
      };
    }
  }

  isImageFile(file: File, allowedImageTypes: string[]): boolean {
    const extension = file.name.split('.')[1];
    return allowedImageTypes.includes(extension);
  }
}
