export enum SiteTitle {
  account = 'Profil',
  activationLink = 'Link aktywacyjny',
  buildings = 'Budynki',
  calculations = 'Kalkulacje',
  confirmEmail = 'Potwierdzanie email',
  contact = 'Kontakt',
  carbonAssistant = 'Doradca węglowy',
  contracts = 'Kontrakty',
  cultivations = 'Uprawy',
  cultivationAssistant = 'Asystent upraw',
  documents = 'Dokumenty',
  emissionCalculations = 'Kalkulator emisji',
  employees = 'Pracownicy',
  farm = 'Gospodarstwo',
  fertilizersDb = 'Baza nawozów',
  fertilizerStockNeeds = 'Potrzeby nawozowe',
  forgottenPassword = 'Przypomnienie hasła',
  login = 'Logowanie',
  machines = 'Maszyny',
  messages = 'Wiadomości',
  monitoring = 'Monitoring maszyn',
  myFields = 'Moje pola',
  observations = 'Obserwacje',
  rucip = 'Rucip',
  pesticides = 'Środki ochrony roślin',
  register = 'Rejestracja',
  treatments = 'Rejestr zabiegów',
  reportCropRotation = 'Raport - płodozmian',
  reportCarbonEmission = 'Raport - zestawienie emisji CO₂',
  reportEmployeeManualHarvest = 'Raport - zbiory pracownika - szczegółowe',
  reportPlotManualHarvest = 'Raport - wydajność plantacji',
  reportTreatmentProductionCost = 'Raport - zestawienie kosztów produkcji',
  reportSummaryManualHarvest = 'Raport - podsumowanie zbioru',
  reportVehicle = 'Raport - praca maszyny',
  reportFuel = 'Raport - paliwo',
  reports = 'Raporty',
  resetPassword = 'Zresetuj hasło',
  satelliteImages = 'Mapy satelitarne',
  settings = 'Handel - zarządzanie partnerami',
  soilAnalysis = 'Badania gleby',
  stockOffers = 'Oferty sprzedażowe',
  suppliers = 'Dostawcy',
  warehouses = 'Magazyny',
  warehousesProductsList = 'Magazyny - produkty',
  warehousesProductStocks = 'Magazyny - stany magazynowe',
  weatherStations = 'Stacje meteorologiczne',
  storageSensors = 'Sensory magazynowe',
  devices = 'Sensory GPS'
}
