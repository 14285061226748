export enum SoilAnalysisValuationType {
  VeryLow = 'VeryLow',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  VeryHigh = 'VeryHigh'
}

export enum SoilAnalysisValuationTypeShort {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}
