import { Component, ChangeDetectionStrategy, AfterViewInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { PureMapService } from '../../common/services/pure-map.service';

@Component({
  selector: 'app-pure-map',
  templateUrl: './pure-map.component.html',
  styleUrls: ['./pure-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureMapComponent implements AfterViewInit {
  @ViewChild('mapDiv') mapDiv: ElementRef;
  map: google.maps.Map;

  @Input() height = 500;
  @Input() tilt = 45;
  @Output() mapInitialized = new EventEmitter<google.maps.Map>();

  constructor(private mapService: PureMapService) {}

  async ngAfterViewInit() {
    this.map = await this.mapService.initMap(this.mapDiv, this.tilt);
    this.mapService.clearMarkers();
    this.mapService.clearPolygons();
    this.mapService.clearMapTypes();
    this.mapInitialized.emit(this.map);
  }
}
