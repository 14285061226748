import { Injectable, NgZone } from '@angular/core';
import { Feature, GeoJsonProperties, Geometry, Point, Polygon, Position } from 'geojson';
import { BehaviorSubject } from 'rxjs';
import DrawingManagerOptions = google.maps.drawing.DrawingManagerOptions;

@Injectable()
export class GoogleMapService {
  private markers = [];
  private polygons = [];
  private polylines = [];
  private drawingManager: google.maps.drawing.DrawingManager;
  private wmsLayer?: google.maps.ImageMapType;

  private tilesLoadedListener: google.maps.MapsEventListener | null = null;
  private isWsmLayerTilesLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isWsmLayerTilesLoading$ = this.isWsmLayerTilesLoading.asObservable();

  private readonly GEOPORTAL_TILES_URL: string = 'https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow';

  constructor(private readonly ngZone: NgZone) {}

  public isGeoJSONPointFeature(feature: Feature): feature is Feature<Point> {
    return feature.geometry.type === 'Point';
  }

  public isGeoJSONPolygonFeature(feature: Feature): feature is Feature<Polygon> {
    return feature.geometry.type == 'Polygon';
  }

  setMapOptions(map: google.maps.Map, options: google.maps.MapOptions) {
    map.setOptions(options);
  }

  createMarker(map: google.maps.Map, options: google.maps.ReadonlyMarkerOptions): google.maps.Marker {
    const marker = new google.maps.Marker(options);
    marker.setMap(map);
    this.markers.push(marker);
    return marker;
  }

  createSingleMarker(map: google.maps.Map, options: google.maps.MarkerOptions): google.maps.Marker {
    this.markers.forEach(x => {
      x.setMap(null);
    });
    const marker = new google.maps.Marker(options);
    marker.setMap(map);
    this.markers.push(marker);
    return marker;
  }

  createPolygon(map: google.maps.Map, options: google.maps.PolygonOptions): google.maps.Polygon {
    const polygon = new google.maps.Polygon(options);
    polygon.setMap(map);

    this.polygons.push(polygon);
    return polygon;
  }

  clearPolygons() {
    this.polygons.forEach(polygon => this.removePolygon(polygon));
    this.polygons = [];
  }

  clearMarkers() {
    this.markers.forEach(marker => this.removeMarker(marker));
    this.markers = [];
  }

  clearPolylines() {
    this.polylines.forEach(polyline => this.removePolyline(polyline));
    this.polylines = [];
  }

  removePolygon(polygon: google.maps.Polygon) {
    polygon.setMap(null);
  }

  removeMarker(marker: google.maps.Marker) {
    marker.setMap(null);
  }

  removePolyline(polyline: google.maps.Polyline) {
    polyline.setMap(null);
  }

  setZoom(map: google.maps.Map, zoomValue = 13): void {
    map.setZoom(zoomValue);
  }

  centerMap(map: google.maps.Map, value: google.maps.LatLng): void {
    map.setCenter(value);
  }

  fitBounds(map: google.maps.Map, bounds: google.maps.LatLngBounds): void {
    map.fitBounds(bounds);
    map.panToBounds(bounds);
  }

  overlayTiles(map: google.maps.Map, imageMapType: google.maps.ImageMapType) {
    map.overlayMapTypes.push(imageMapType);
  }

  clearMapTypes(map: google.maps.Map) {
    map.overlayMapTypes.clear();
  }

  calculatePolygonArea(paths: google.maps.MVCArray<google.maps.LatLng> | google.maps.LatLng[]): number {
    const meterToHa = 10000;
    const areaValue = google.maps.geometry.spherical.computeArea(paths) / meterToHa;
    return areaValue;
  }

  public calculatePolygonAreaInHa(polygon: google.maps.Polygon): number {
    const areaInHa = google.maps.geometry.spherical.computeArea(polygon.getPath());
    const roundedAreaInHa = Math.round(((areaInHa + Number.EPSILON) / 10000) * 100) / 100;
    return roundedAreaInHa;
  }

  createDrawingManager(map: google.maps.Map, options: DrawingManagerOptions): google.maps.drawing.DrawingManager {
    if (this.drawingManager) {
      this.drawingManager.setMap(null);
    }
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    return this.drawingManager;
  }

  public createGeoJSONFeatureFromPolygon(polygon: google.maps.Polygon, properties?: GeoJsonProperties): Feature<Polygon> {
    const paths = polygon.getPaths().getArray();
    const coordinates = paths[0].getArray().map(latLng => [latLng.lng(), latLng.lat()]);

    const geoJSONPolygon: Polygon = {
      type: 'Polygon',
      coordinates: [coordinates]
    };

    const feature: Feature<Polygon> = {
      type: 'Feature',
      geometry: geoJSONPolygon,
      properties: { ...properties }
    };

    return feature;
  }

  public createPolygonFromGeoJSONFeature(
    map: google.maps.Map,
    feature: Feature<Polygon>,
    polygonOptions?: google.maps.PolygonOptions
  ): google.maps.Polygon {
    const polygon = feature.geometry;
    const coordinates = polygon.coordinates;
    const paths = coordinates.map(ring => ring.map(coord => new google.maps.LatLng(coord[1], coord[0])));

    const defaultColor = '#ffff00';

    const defaultPolygonOptions: google.maps.PolygonOptions = {
      paths: paths,
      strokeColor: polygonOptions && polygonOptions.strokeColor ? polygonOptions.strokeColor : defaultColor,
      strokeOpacity: polygonOptions && polygonOptions.strokeOpacity ? polygonOptions.strokeOpacity : 0.8,
      strokeWeight: polygonOptions && polygonOptions.strokeWeight ? polygonOptions.strokeWeight : 2,
      fillColor: polygonOptions && polygonOptions.fillColor ? polygonOptions.fillColor : defaultColor,
      fillOpacity: polygonOptions && polygonOptions.fillOpacity ? polygonOptions.fillOpacity : 0.35,
      zIndex: 100,
      map: map
    };

    const finalPolygonOptions = { ...defaultPolygonOptions, ...polygonOptions };

    return new google.maps.Polygon(finalPolygonOptions);
  }

  public mapPolygonGeometryToPaths(locationGeometry: Geometry): google.maps.LatLng[] | google.maps.LatLng[][] {
    if (locationGeometry.type === 'Polygon') {
      return locationGeometry.coordinates.map(coordinate => {
        return coordinate.map(x => {
          return new google.maps.LatLng({
            lat: x[1],
            lng: x[0]
          });
        });
      });
    }

    return [];
  }

  public mapPointGeometryToPaths(locationGeometry: Geometry): google.maps.LatLng {
    if (locationGeometry.type === 'Point') {
      return new google.maps.LatLng(locationGeometry.coordinates[1], locationGeometry.coordinates[0]);
    }

    return null;
  }

  public mapPolygonToFeature(polygon: google.maps.Polygon): Feature<Polygon> {
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: this.getPolygonCoordinates(polygon)
      },
      properties: {}
    };
  }

  public mapMarkerToFeature(marker: google.maps.Marker): Feature<Point> {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [marker.getPosition().lng(), marker.getPosition().lat()]
      },
      properties: {}
    };
  }

  public mapPointToFeature(lat: number, long: number): Feature<Point> {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [long, lat]
      },
      properties: {}
    };
  }

  public createFeaturePolygonFromCoordinates(coordinates: Position[][]): Feature<Polygon> {
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: coordinates
      },
      properties: {}
    };
  }

  public getCenterPoint(overlay: google.maps.Marker | google.maps.Polygon): { lat: number; lng: number } {
    if (overlay instanceof google.maps.Marker) {
      const position = overlay.getPosition();
      return { lat: position.lat(), lng: position.lng() };
    }

    if (overlay instanceof google.maps.Polygon) {
      const bounds = new google.maps.LatLngBounds();
      overlay.getPath().forEach(point => bounds.extend(point));
      const center = bounds.getCenter();
      return { lat: center.lat(), lng: center.lng() };
    }

    throw new Error('Unsupported overlay type');
  }

  public getCenterPointFromFeatures(features: Array<Feature>): { lat: number; lng: number } | null {
    for (const feature of features) {
      if (feature.geometry.type === 'Point') {
        const position = feature.geometry.coordinates as Position;
        return { lat: position[1], lng: position[0] };
      }
    }

    for (const feature of features) {
      if (feature.geometry.type === 'Polygon') {
        const polygon = feature.geometry.coordinates as Position[][];
        let totalX = 0,
          totalY = 0,
          count = 0;

        polygon.forEach(ring => {
          ring.forEach(([x, y]) => {
            totalX += x;
            totalY += y;
            count++;
          });
        });

        if (count === 0) {
          return null;
        }

        return { lng: totalY / count, lat: totalX / count };
      }
    }

    return null;
  }

  public drawPolyline(map: google.maps.Map, path: google.maps.LatLng[], options: google.maps.PolylineOptions): google.maps.Polyline {
    const polyline = new google.maps.Polyline({
      ...options,
      path: path
    });
    polyline.setMap(map);
    this.polylines.push(polyline);
    return polyline;
  }

  public createCustomMarkerIconWithNumber(number: number): google.maps.Icon {
    const width = 65;
    const height = 50;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');
    if (context) {
      this.drawTeardrop(context, width, height);
      this.drawInnerCircleAndNumber(context, number);
    }

    return {
      url: canvas.toDataURL(),
      anchor: new google.maps.Point(width / 2, height)
    };
  }

  private drawTeardrop(ctx: CanvasRenderingContext2D, width: number, height: number) {
    const xoff = 0;
    const yoff = 0;
    const xmul = width / 334;
    const ymul = height / 258;

    ctx.beginPath();
    ctx.moveTo(247 * xmul + xoff, 108 * ymul + yoff);
    ctx.bezierCurveTo(242 * xmul + xoff, 44 * ymul + yoff, 190 * xmul + xoff, 33 * ymul + yoff, 170 * xmul + xoff, 33 * ymul + yoff);
    ctx.bezierCurveTo(
      150 * xmul + xoff,
      32.99999999999999 * ymul + yoff,
      97 * xmul + xoff,
      45 * ymul + yoff,
      95 * xmul + xoff,
      109 * ymul + yoff
    );
    ctx.bezierCurveTo(
      94.37530495244557 * xmul + xoff,
      128.99024152174158 * ymul + yoff,
      108 * xmul + xoff,
      185 * ymul + yoff,
      171 * xmul + xoff,
      227 * ymul + yoff
    );
    ctx.bezierCurveTo(230 * xmul + xoff, 185 * ymul + yoff, 247 * xmul + xoff, 132 * ymul + yoff, 247 * xmul + xoff, 109 * ymul + yoff);
    ctx.closePath();
    ctx.fillStyle = '#FFFFFF';
    ctx.fill();
    ctx.strokeStyle = '#FFFFFF';
    ctx.lineWidth = 2;
    ctx.stroke();
  }

  private drawInnerCircleAndNumber(ctx: CanvasRenderingContext2D, number: number) {
    const circleCenterX = 33;
    const circleCenterY = 21;
    const circleRadius = 13;
    const gradient = ctx.createLinearGradient(circleCenterX, circleCenterY - circleRadius, circleCenterX, circleCenterY + circleRadius);
    gradient.addColorStop(0, '#34a5ad');
    gradient.addColorStop(1, '#096870');

    ctx.beginPath();
    ctx.arc(circleCenterX, circleCenterY, circleRadius, 0, 2 * Math.PI, false);
    ctx.fillStyle = gradient;
    ctx.fill();

    ctx.font = 'bold 18px Inter';
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(number.toString(), circleCenterX, circleCenterY);
  }

  public addWsmGeoPortalLayer(map: google.maps.Map, minZoom = 17, maxZoom = 21) {
    const TILE_SIZE = 256;
    const ORIGIN_SHIFT = 20037508.342789244;
    const INITIAL_RESOLUTION = 156543.03392804097;

    if (this.wmsLayer) {
      return;
    }

    this.wmsLayer = new google.maps.ImageMapType({
      getTileUrl: (coord, zoom) => {
        if (zoom < minZoom || zoom > maxZoom) {
          return '';
        }

        this.ngZone.run(() => {
          if (!this.isWsmLayerTilesLoading.getValue()) {
            this.isWsmLayerTilesLoading.next(true);
          }
        });
        const z = Math.pow(2, zoom);

        // Calculate the Tile Bounds in EPSG:3857
        const minx3857 = (coord.x * TILE_SIZE * INITIAL_RESOLUTION) / z - ORIGIN_SHIFT;
        const maxx3857 = ((coord.x + 1) * TILE_SIZE * INITIAL_RESOLUTION) / z - ORIGIN_SHIFT;
        const miny3857 = ORIGIN_SHIFT - ((coord.y + 1) * TILE_SIZE * INITIAL_RESOLUTION) / z;
        const maxy3857 = ORIGIN_SHIFT - (coord.y * TILE_SIZE * INITIAL_RESOLUTION) / z;

        const bbox = [minx3857, miny3857, maxx3857, maxy3857].join(',');

        return `${this.GEOPORTAL_TILES_URL}?service=WMS&version=1.1.0&request=GetMap&layers=dzialki,numery_dzialek&styles=&bbox=${bbox}&width=256&height=256&srs=EPSG:3857&format=image/png&transparent=true`;
      },
      tileSize: new google.maps.Size(TILE_SIZE, TILE_SIZE),
      opacity: 0.9,
      name: 'KrajowaIntegracjaEwidencjiGruntow'
    });

    map.overlayMapTypes.insertAt(0, this.wmsLayer);
    this.monitorTileLoading(this.wmsLayer);
  }

  public removeWsmGeoPortalLayer(map: google.maps.Map) {
    if (!this.wmsLayer) {
      return;
    }

    const index = map.overlayMapTypes.getArray().indexOf(this.wmsLayer);
    if (index === -1) {
      return;
    }

    this.ngZone.run(() => {
      map.overlayMapTypes.removeAt(index);
      this.wmsLayer = undefined;

      if (this.tilesLoadedListener) {
        google.maps.event.removeListener(this.tilesLoadedListener);
        this.tilesLoadedListener = null;
      }

      this.isWsmLayerTilesLoading.next(false);
    });
  }

  private monitorTileLoading(layer: google.maps.ImageMapType) {
    this.tilesLoadedListener = google.maps.event.addListener(layer, 'tilesloaded', () => {
      this.ngZone.run(() => {
        this.isWsmLayerTilesLoading.next(false);
      });
    });
  }

  private getPolygonCoordinates(polygon: google.maps.Polygon): Position[][] {
    return polygon
      .getPaths()
      .getArray()
      .map(path => {
        return path.getArray().map(x => {
          return [x.lng(), x.lat()];
        });
      });
  }
}
