import { Injectable } from '@angular/core';
import { ApplicationInsights, eSeverityLevel } from '@microsoft/applicationinsights-web';
import { AppConfigurationService } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private isInitialized = false;
  private appInsights: ApplicationInsights;

  constructor(private appConfigurationService: AppConfigurationService) {}

  public trackException(errorObject: Error) {
    if (!this.isInitialized) {
      return;
    }

    if (!this.appInsights) {
      return;
    }

    this.appInsights.trackException({ exception: errorObject, severityLevel: eSeverityLevel.Error });
    this.appInsights.flush();
  }

  public initialize() {
    if (this.isInitialized) {
      return;
    }

    if (!this.appConfigurationService.configuration.appinsight_key) {
      console.error('Application insights initialization skipped due to missing key in config.');
      this.isInitialized = true;
      return;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.appConfigurationService.configuration.appinsight_key
      }
    });
    this.appInsights.loadAppInsights();
    this.isInitialized = true;
  }
}
