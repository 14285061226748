export enum FertilizerType {
  Calcium = 'Calcium',
  Foliar = 'Foliar',
  GrowStimulator = 'GrowStimulator',
  Magnesium = 'Magnesium',
  Mineral = 'Mineral',
  MultiComponents = 'MultiComponents',
  Natural = 'Natural',
  Nitrogen = 'Nitrogen',
  NitrogenWithSulfur = 'NitrogenWithSulfur',
  Organic = 'Organic',
  OrganicMineral = 'OrganicMineral',
  Phosphorus = 'Phosphorus',
  Potassium = 'Potassium',
  Sulfur = 'Sulfur',
  TwoComponents = 'TwoComponents',
  Universal = 'Universal'
}

export enum FertilizerDbType {
  Nitrogen = 'Nitrogen',
  Phosphorus = 'Phosphorus',
  Potassium = 'Potassium',
  MultiComponents = 'MultiComponents',
  Calcium = 'Calcium',
  Sulfur = 'Sulfur',
  Natural = 'Natural',
  GrowStimulator = 'GrowStimulator',
  Other = 'Other'
}
