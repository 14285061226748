import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { CurrentUserProxyService } from './current-user-proxy.service';
import { AuthService } from './services/auth.service';
import { IUserOrganizationDto } from './user-organization.dto';
import { AuthStoreService } from './services/auth-store.service';

@Injectable({
  providedIn: 'root'
})
export class UserContextService {
  private userOrganizations: BehaviorSubject<IUserOrganizationDto[]> = new BehaviorSubject([]);
  private currentUserOrganization: BehaviorSubject<IUserOrganizationDto> = new BehaviorSubject(null);

  public currentUserOrganization$: Observable<IUserOrganizationDto>;
  public userOrganizations$: Observable<IUserOrganizationDto[]>;

  constructor(
    private currentUserProxyService: CurrentUserProxyService,
    private authService: AuthService,
    private authStore: AuthStoreService
  ) {
    this.currentUserOrganization$ = this.currentUserOrganization.asObservable();
    this.userOrganizations$ = this.userOrganizations.asObservable();
  }

  get currentOrganizationId(): string | undefined {
    return this.currentUserOrganization.value && this.currentUserOrganization.value.id;
  }

  public clean() {
    this.userOrganizations.next(null);
    this.currentUserOrganization.next(null);
  }

  public tryGetCurrentUserData$(): Observable<IUserOrganizationDto> {
    return this.getCurrentUserOrganization$();
  }

  private getCurrentUserOrganization$(): Observable<IUserOrganizationDto> {
    return this.currentUserOrganization$.pipe(
      switchMap(currentUserOrganization => {
        if (currentUserOrganization != null) {
          return of(currentUserOrganization);
        } else {
          return this.refresh$();
        }
      }),
      take(1)
    );
  }

  public selectCurrentUserOrganization(selectedOrganization: IUserOrganizationDto): Observable<void> {
    return this.authService.refreshTokenOnOrganizationChange(selectedOrganization.id).pipe(
      tap(() => {
        this.currentUserOrganization.next(selectedOrganization);
      })
    );
  }

  private refresh$(): Observable<IUserOrganizationDto> {
    return this.currentUserProxyService.getUserOrganizations().pipe(
      map(response => {
        if (response.length > 0) {
          this.userOrganizations.next(response);
          const defaultOrganization = this.getDefaultOrganization(response);
          this.currentUserOrganization.next(defaultOrganization);

          return defaultOrganization;
        }

        throw new Error(`Organization for user is not defined`);
      }),
      take(1)
    );
  }

  private getDefaultOrganization(organizations: IUserOrganizationDto[]): IUserOrganizationDto | null {
    if (organizations.length > 0) {
      const organizationId = this.authStore.getUserOrganizationId;
      return organizations.find(x => x.id === organizationId) || organizations[0];
    }

    return null;
  }
}
