import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
  private static readonly SECOND_RATIO = 60;
  private static readonly MINUTE_RATIO = 3600;
  private static readonly PAD_LENGTH = 2;

  transform(value: number) {
    const seconds = value % TimePipe.SECOND_RATIO;
    const minutes = value % TimePipe.MINUTE_RATIO;
    const hours = Math.floor(value / TimePipe.MINUTE_RATIO);
    return `${TimePipe.formatValue(hours)}:${TimePipe.formatValue(minutes)}:${TimePipe.formatValue(seconds)}`;
  }

  private static formatValue(value: number): string {
    return value.toString().padStart(TimePipe.PAD_LENGTH, '0');
  }
}
