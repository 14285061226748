// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createFormData(data: any) {
  const formData = new FormData();

  buildFormData(formData, data, '', false);

  return formData;
}

function buildFormData(formData: FormData, data: string | Blob, parentKey: string, isPartOfArrayOfFiles: boolean) {
  if (data == null) {
    return;
  }

  if (typeof data !== 'object') {
    formData.append(parentKey, data);
    return;
  }

  if (data instanceof Date) {
    formData.append(parentKey, data);
    return;
  }

  if (data instanceof File) {
    formData.append(parentKey, data, data.name);
    return;
  }

  Object.keys(data).forEach(key => {
    const newParentKey = buildParentKey(data, key, parentKey, isPartOfArrayOfFiles);
    buildFormData(formData, data[key], newParentKey, isArrayOfFiles(data[key]));
  });
}

function buildParentKey(data: string | Blob, key: string, parentKey: string, isPartOfArrayOfFiles: boolean) {
  return parentKey
    ? isFile(data[key])
      ? isPartOfArrayOfFiles
        ? `${parentKey}`
        : `${parentKey}.${key}`
      : isArrayOfFiles(data[key])
        ? `${parentKey}.${key}`
        : `${parentKey}[${key}]`
    : key;
}

function isFile(data: string | Blob): boolean {
  return data != null && data instanceof File;
}

function isArrayOfFiles(data: string | Blob): boolean {
  if (data != null && data instanceof Array) {
    return data.filter(x => x != null).every(x => x instanceof File);
  }
  return false;
}
