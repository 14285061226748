export function toCamel(oldObject) {
  let newObject;
  let originalKey;
  let newKey;
  let value;
  if (oldObject instanceof Array) {
    return oldObject.map(arrayItem => {
      if (typeof arrayItem === 'object') {
        arrayItem = toCamel(arrayItem);
      }
      return arrayItem;
    });
  } else {
    newObject = {};
    for (originalKey in oldObject) {
      if (Object.prototype.hasOwnProperty.call(oldObject, originalKey)) {
        newKey = (originalKey.charAt(0).toLowerCase() + originalKey.slice(1) || originalKey).toString();
        value = oldObject[originalKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = toCamel(value);
        }
        newObject[newKey] = value;
      }
    }
  }
  return newObject;
}
