import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-action-secondary-button',
  templateUrl: './action-secondary-button.component.html'
})
export class ActionButtonSecondaryComponent {
  @Input() labelTranslationKey: string;
  @Input() icon = 'ph ph-download-simple';
  @Input() isVisible = true;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  public click(): void {
    this.onClick.emit();
  }
}
