import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigurationService } from 'farmcloud-core';
import { IUserOrganizationDto } from './user-organization.dto';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserProxyService {
  private readonly userApiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigurationService
  ) {
    this.userApiUrl = `${this.config.configuration.api_url}/api/User`;
  }

  getUserOrganizations(): Observable<IUserOrganizationDto[]> {
    return this.httpClient.get<IUserOrganizationDto[]>(`${this.userApiUrl}/Organizations`);
  }
}
