import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from 'farmcloud-core';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LangSelectorComponent implements OnInit {
  private flagUrl = './assets/img/flags/';

  languages: { key: string; flagUrl: string }[];
  selectedLanguage: { key: string; flagUrl: string };
  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.languages = this.translateService.supportedLanguages().map(l => this.mapLanguage(l));
    this.selectedLanguage = this.mapLanguage(this.translateService.getLanguage());
  }

  changeLanguage(languageKey) {
    this.translateService.setLanguage(languageKey);
    this.selectedLanguage = this.mapLanguage(this.translateService.getLanguage());
  }

  trackByFn(index, item: { key: string; flagUrl: string }) {
    return item.key;
  }

  private mapLanguage(language: string) {
    return {
      key: language,
      flagUrl: `${this.flagUrl}${language}.png`
    };
  }
}
