import { Component } from '@angular/core';
import { NotificationService } from '@farm-portal/core/layout/navbar/navbar-notifications/notification.service';
import { MessageDto } from '@farm-portal/core/message/models/message.dto';

@Component({
  selector: 'app-navbar-notifications',
  templateUrl: './navbar-notifications.component.html',
  styleUrls: ['./navbar-notifications.component.scss']
})
export class NavbarNotificationsComponent {
  constructor(public readonly notificationService: NotificationService) {}

  public onNotificationClick(notification: MessageDto) {
    return this.notificationService.markAsRead(notification);
  }

  public onMarkAllNotificationsAsReadClick() {
    this.notificationService.markAllAsRead();
  }
}
