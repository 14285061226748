import { DateTime } from 'luxon';
import { DatePickerValue, TimePickerValue } from './date-picker-value.model';

export function mapDatePickerValueToISOString(value: DatePickerValue, addDays = 0) {
  if (value == null || typeof value === 'string') {
    return undefined;
  }

  const date = new Date(value.year, value.month - 1, value.day);
  date.setDate(date.getDate() + addDays);
  return date.toISOString();
}

export function mapDatePickerValueToDate(value: DatePickerValue, addDays = 0) {
  if (value == null || typeof value === 'string') {
    return undefined;
  }

  const date = new Date(value.year, value.month - 1, value.day);
  date.setDate(date.getDate() + addDays);
  return date;
}

export function mapDateTimePickerValueToISOString(datePickerValue: DatePickerValue, timePickerValue: TimePickerValue): string | undefined {
  if (datePickerValue == null || typeof datePickerValue === 'string' || timePickerValue == null || typeof timePickerValue === 'string') {
    return undefined;
  }

  const data = new Date(
    datePickerValue.year,
    datePickerValue.month - 1,
    datePickerValue.day,
    timePickerValue.hour,
    timePickerValue.minute,
    timePickerValue.second
  );

  return toIsoWithTimeZoneString(data);
}

export function mapDatePickerValueToUnixTimestamp(value: DatePickerValue) {
  if (value == null) {
    return undefined;
  }

  return new Date(value.year, value.month - 1, value.day).getTime() / 1000;
}

export function mapISOStringToDatePickerValue(value: string): DatePickerValue | null {
  const dateTime: DateTime = value == null ? null : DateTime.fromISO(value);

  if (dateTime == null) {
    return null;
  }

  return {
    year: dateTime.year,
    month: dateTime.month,
    day: dateTime.day
  };
}

export function mapISOStringToTimePickerValue(value: string): TimePickerValue | null {
  const dateTime: DateTime = value == null ? null : DateTime.fromISO(value);

  if (dateTime == null) {
    return null;
  }

  return {
    hour: dateTime.hour,
    minute: dateTime.minute,
    second: dateTime.second
  };
}

export function mapUnixTimestampToDatePickerValue(value: number): DatePickerValue | null {
  const date: Date = value == null ? null : new Date(value * 1000);

  if (date == null) {
    return null;
  }

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  };
}

function toIsoWithTimeZoneString(date: Date) {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      return (num < 10 ? '0' : '') + num;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
}

export function getYearsRangeOptionsForDynamicSelectModel(): {
  label: string;
  value: number;
}[] {
  const from = 1980;
  const to = new Date().getFullYear();

  return getRangeOptionsForDynamicSelectModel(from, to).reverse();
}

export function getRangeOptionsForDynamicSelectModel(
  from: number,
  to: number
): {
  label: string;
  value: number;
}[] {
  const rangeLength = to - from + 1;

  return Array.from({ length: rangeLength }, (v, k) => k + from).map(n => {
    return {
      label: n.toString(),
      value: n
    };
  });
}
