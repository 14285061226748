import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUtilService {
  public downloadFile = (link: string, target: '_blank' | '_self' = '_blank') => {
    window.open(link, target);
  };

  public downloadFileWithNameAsync = async (url: string, fileName: string) => {
    const response = await fetch(url, { method: 'GET' });
    const blob = await response.blob();
    const urlDownload = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = urlDownload;
    link.setAttribute('download', fileName);
    link.click();
  };
}
