import { Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'shortDate'
})
export class ShortDatePipe extends DatePipe {
  public static readonly DateFormat = 'dd.MM.yyyy';

  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null {
    if (value === null) {
      return null;
    }
    return super.transform(value, ShortDatePipe.DateFormat, timezone, locale);
  }
}
