import { Pipe, PipeTransform } from '@angular/core';
import { MessageType } from '../enums';

@Pipe({
  name: 'messageTypeIcon'
})
export class MessageTypeIconPipe implements PipeTransform {
  transform(value: MessageType): string {
    switch (value) {
      case MessageType.Advertisement:
        return 'ft-globe info';
      case MessageType.Alert:
        return 'ft-alert-circle danger';
      case MessageType.Information:
        return 'ft-info info';
      case MessageType.Reminder:
        return 'ft-bell warning';
      case MessageType.Standard:
        return 'ft-mail primary';
      case MessageType.Warning:
        return 'ft-alert-triangle warning';
      default:
        return 'ft-mail info';
    }
  }
}
