import { Directive, ElementRef, OnInit, Self } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

// Fixes issue where ng-select doesn't take in account fieldset disabled status
// Fixed in ng-select > 4.x
// https://github.com/ng-select/ng-select/issues/751
@Directive({
  selector: '[appFieldsetDisabled]'
})
export class FieldsetDisabledDirective implements OnInit {
  constructor(
    private el: ElementRef,
    @Self() private ngSelect: NgSelectComponent
  ) {}

  ngOnInit() {
    const fieldsetElement = this.el.nativeElement.closest('fieldset');
    if (fieldsetElement) {
      this.setState(fieldsetElement.disabled);
      this.listenToChange(fieldsetElement);
    }
  }

  private setState(isFieldsetDisabled: boolean) {
    const isReadonly = this.ngSelect.readonly;
    this.ngSelect.setDisabledState(isFieldsetDisabled);

    if (isFieldsetDisabled || isReadonly) {
      this.el.nativeElement.classList.add('ng-select-disabled');
      return;
    }
    this.el.nativeElement.classList.remove('ng-select-disabled');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listenToChange(fieldsetElement: any) {
    const observer = new MutationObserver((mutations, observer) => {
      if (mutations[0].attributeName === 'disabled') {
        this.setState(fieldsetElement.disabled);
        observer.disconnect();
        this.listenToChange(fieldsetElement);
      }
    });
    observer.observe(fieldsetElement, { attributes: true });
  }
}
