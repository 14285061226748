import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'plusOrMinus' })
export class PlusOrMinusPipe implements PipeTransform {
  transform(value: string): string | null {
    if (parseFloat(value) >= 0) {
      return `+ ${value}`;
    } else if (parseFloat(value) < 0) {
      return `- ${value}`;
    }

    return value;
  }
}
