export enum DictionaryType {
  ActiveSubstanceType,
  Agrofag,
  CultivationCrop,
  CultivationInterCrop,
  FertilizerType,
  MachineType,
  PesticideType,
  SeedType,
  TreatmentType,
  BuildingType
}
