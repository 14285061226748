import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyPositive]'
})
export class OnlyPositiveDirective {
  private static readonly regex: RegExp = new RegExp(/^[^0-9]/g);
  private static readonly specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  @Input('appOnlyPositive') onlyPositives = false;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: UIEvent) {
    if (!this.onlyPositives) {
      return;
    }

    const inputValue = this.el.nativeElement.value.toString();

    if (inputValue.endsWith(',') || inputValue.endsWith('.')) {
      return;
    }

    const asNumber = Number(inputValue);
    this.el.nativeElement.value = asNumber < 0 ? '0' : inputValue;
    if (asNumber < 0) {
      event.stopPropagation();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.onlyPositives) {
      return;
    }

    if (OnlyPositiveDirective.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (event.key === '-') {
      event.preventDefault();
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && String(next).match(OnlyPositiveDirective.regex)) {
      event.preventDefault();
      return;
    }
  }
}
