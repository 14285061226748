import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private statusChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public status$ = this.statusChange.asObservable();

  display() {
    this.statusChange.next(true);
  }

  hide() {
    this.statusChange.next(false);
  }
}
