export enum CountryCode {
  'af' = 'af',
  'al' = 'al',
  'dz' = 'dz',
  'ad' = 'ad',
  'ao' = 'ao',
  'ai' = 'ai',
  'aq' = 'aq',
  'ag' = 'ag',
  'sa' = 'sa',
  'ar' = 'ar',
  'am' = 'am',
  'aw' = 'aw',
  'au' = 'au',
  'at' = 'at',
  'az' = 'az',
  'bs' = 'bs',
  'bh' = 'bh',
  'bd' = 'bd',
  'bb' = 'bb',
  'be' = 'be',
  'bz' = 'bz',
  'bj' = 'bj',
  'bm' = 'bm',
  'bt' = 'bt',
  'by' = 'by',
  'bo' = 'bo',
  'bq' = 'bq',
  'ba' = 'ba',
  'bw' = 'bw',
  'br' = 'br',
  'bn' = 'bn',
  'io' = 'io',
  'vg' = 'vg',
  'bg' = 'bg',
  'bf' = 'bf',
  'bi' = 'bi',
  'cl' = 'cl',
  'cn' = 'cn',
  'hr' = 'hr',
  'cw' = 'cw',
  'cy' = 'cy',
  'td' = 'td',
  'me' = 'me',
  'cz' = 'cz',
  'um' = 'um',
  'dk' = 'dk',
  'cd' = 'cd',
  'dm' = 'dm',
  'do' = 'do',
  'dj' = 'dj',
  'eg' = 'eg',
  'ec' = 'ec',
  'er' = 'er',
  'ee' = 'ee',
  'et' = 'et',
  'fk' = 'fk',
  'fj' = 'fj',
  'ph' = 'ph',
  'fi' = 'fi',
  'fr' = 'fr',
  'tf' = 'tf',
  'ga' = 'ga',
  'gm' = 'gm',
  'gs' = 'gs',
  'gh' = 'gh',
  'gi' = 'gi',
  'gr' = 'gr',
  'gd' = 'gd',
  'gl' = 'gl',
  'ge' = 'ge',
  'gu' = 'gu',
  'gg' = 'gg',
  'gf' = 'gf',
  'gy' = 'gy',
  'gp' = 'gp',
  'gt' = 'gt',
  'gw' = 'gw',
  'gq' = 'gq',
  'gn' = 'gn',
  'ht' = 'ht',
  'es' = 'es',
  'nl' = 'nl',
  'hn' = 'hn',
  'hk' = 'hk',
  'in' = 'in',
  'id' = 'id',
  'iq' = 'iq',
  'ir' = 'ir',
  'ie' = 'ie',
  'is' = 'is',
  'il' = 'il',
  'jm' = 'jm',
  'jp' = 'jp',
  'ye' = 'ye',
  'je' = 'je',
  'jo' = 'jo',
  'ky' = 'ky',
  'kh' = 'kh',
  'cm' = 'cm',
  'ca' = 'ca',
  'qa' = 'qa',
  'kz' = 'kz',
  'ke' = 'ke',
  'kg' = 'kg',
  'ki' = 'ki',
  'co' = 'co',
  'km' = 'km',
  'cg' = 'cg',
  'kr' = 'kr',
  'kp' = 'kp',
  'cr' = 'cr',
  'cu' = 'cu',
  'kw' = 'kw',
  'la' = 'la',
  'ls' = 'ls',
  'lb' = 'lb',
  'lr' = 'lr',
  'ly' = 'ly',
  'li' = 'li',
  'lt' = 'lt',
  'lu' = 'lu',
  'lv' = 'lv',
  'mk' = 'mk',
  'mg' = 'mg',
  'yt' = 'yt',
  'mo' = 'mo',
  'mw' = 'mw',
  'mv' = 'mv',
  'my' = 'my',
  'ml' = 'ml',
  'mt' = 'mt',
  'mp' = 'mp',
  'ma' = 'ma',
  'mq' = 'mq',
  'mr' = 'mr',
  'mu' = 'mu',
  'mx' = 'mx',
  'fm' = 'fm',
  'mm' = 'mm',
  'md' = 'md',
  'mc' = 'mc',
  'mn' = 'mn',
  'ms' = 'ms',
  'mz' = 'mz',
  'na' = 'na',
  'nr' = 'nr',
  'np' = 'np',
  'de' = 'de',
  'ne' = 'ne',
  'ng' = 'ng',
  'ni' = 'ni',
  'nu' = 'nu',
  'nf' = 'nf',
  'no' = 'no',
  'nc' = 'nc',
  'nz' = 'nz',
  'om' = 'om',
  'pk' = 'pk',
  'pw' = 'pw',
  'ps' = 'ps',
  'pa' = 'pa',
  'pg' = 'pg',
  'py' = 'py',
  'pe' = 'pe',
  'pn' = 'pn',
  'pf' = 'pf',
  'pl' = 'pl',
  'pr' = 'pr',
  'pt' = 'pt',
  'za' = 'za',
  'cf' = 'cf',
  'cv' = 'cv',
  're' = 're',
  'ru' = 'ru',
  'ro' = 'ro',
  'rw' = 'rw',
  'eh' = 'eh',
  'kn' = 'kn',
  'lc' = 'lc',
  'vc' = 'vc',
  'bl' = 'bl',
  'mf' = 'mf',
  'pm' = 'pm',
  'sv' = 'sv',
  'as' = 'as',
  'ws' = 'ws',
  'sm' = 'sm',
  'sn' = 'sn',
  'rs' = 'rs',
  'sc' = 'sc',
  'sl' = 'sl',
  'sg' = 'sg',
  'sx' = 'sx',
  'sk' = 'sk',
  'si' = 'si',
  'so' = 'so',
  'lk' = 'lk',
  'us' = 'us',
  'sz' = 'sz',
  'sd' = 'sd',
  'ss' = 'ss',
  'sr' = 'sr',
  'sj' = 'sj',
  'sy' = 'sy',
  'ch' = 'ch',
  'se' = 'se',
  'tj' = 'tj',
  'th' = 'th',
  'tw' = 'tw',
  'tz' = 'tz',
  'tl' = 'tl',
  'tg' = 'tg',
  'tk' = 'tk',
  'to' = 'to',
  'tt' = 'tt',
  'tn' = 'tn',
  'tr' = 'tr',
  'tm' = 'tm',
  'tc' = 'tc',
  'tv' = 'tv',
  'ug' = 'ug',
  'ua' = 'ua',
  'uy' = 'uy',
  'uz' = 'uz',
  'vu' = 'vu',
  'wf' = 'wf',
  'va' = 'va',
  've' = 've',
  'hu' = 'hu',
  'gb' = 'gb',
  'vn' = 'vn',
  'it' = 'it',
  'ci' = 'ci',
  'bv' = 'bv',
  'cx' = 'cx',
  'im' = 'im',
  'sh' = 'sh',
  'ax' = 'ax',
  'ck' = 'ck',
  'vi' = 'vi',
  'hm' = 'hm',
  'cc' = 'cc',
  'mh' = 'mh',
  'fo' = 'fo',
  'sb' = 'sb',
  'st' = 'st',
  'zm' = 'zm',
  'zw' = 'zw',
  'ae' = 'ae'
}
