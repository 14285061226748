import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlobUtilService {
  private static readonly MIME_INDEX = 0;
  private static readonly DATA_INDEX = 1;

  dataUrlToBlob(dataUrl: string): { size: number; blob: Blob } {
    const parts = dataUrl.split(',');
    const byteString = atob(parts[BlobUtilService.DATA_INDEX]);
    const mimeString = parts[BlobUtilService.MIME_INDEX].split(':')[1].split(';')[0];
    const buffer = new ArrayBuffer(byteString.length);
    const byteArray = new Uint8Array(buffer);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    return {
      size: byteString.length,
      blob: new Blob([buffer], { type: mimeString })
    };
  }

  blobToFile(theBlob: Blob, fileName: string): File {
    return new File([theBlob], fileName, { type: theBlob.type });
  }

  blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      if (blob instanceof Blob) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const realFileReader = (reader as any)._realReader;
        if (realFileReader) {
          reader = realFileReader;
        }
      }
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(blob);
    });
  }
}
