import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '../translation/translate.service';
import { ErrorMessageService } from '../error-handling/error-message.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
    private errorMessageService: ErrorMessageService
  ) {}

  error(error: Error | HttpErrorResponse, defaultMessage?: string) {
    const errorMessage = this.errorMessageService.getTranslatedErrorMessage(error, defaultMessage);
    this.toastr.error(errorMessage);
  }

  success(messageTranslationKey: string) {
    this.toastr.success(this.translateService.translate(messageTranslationKey));
  }

  successTopCenter(messageTranslationKey: string) {
    this.toastr.success(this.translateService.translate(messageTranslationKey), null, { positionClass: 'toast-top-center' });
  }

  info(messageTranslationKey: string) {
    this.toastr.info(this.translateService.translate(messageTranslationKey));
  }

  warning(messageTranslationKey: string) {
    this.toastr.warning(this.translateService.translate(messageTranslationKey));
  }
}
