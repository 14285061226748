export enum ProductCatalogAuditStatus {
  SampleNotTaken = 'SampleNotTaken',
  SampleTaken = 'SampleTaken',
  NotSentToLaboratory = 'NotSentToLaboratory',
  SentToLaboratory = 'SentToLaboratory',
  AwaitingResultComment = 'AwaitingResultComment',
  AwaitingDirectorComment = 'AwaitingDirectorComment',
  AwaitingSupplierComment = 'AwaitingSupplierComment',
  ResultReceived = 'ResultReceived',
  Completed = 'Completed'
}
