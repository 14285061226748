import { Injectable } from '@angular/core';
import { DictionaryType } from '../../dictionary-type.enum';
import { DictionaryMode } from '../../dictionary-mode.enum';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICultivationDictionaryModel } from './cultivation-dictionary.model';
import { AppConfigurationService } from '../../../configuration/app-configuration.service';
import { DictionaryServiceBase } from '../common/dictionary-service-base';

@Injectable()
export class CultivationCropDictionaryService extends DictionaryServiceBase<ICultivationDictionaryModel[]> {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigurationService
  ) {
    super();
  }

  type: DictionaryType = DictionaryType.CultivationCrop;
  mode: DictionaryMode = DictionaryMode.Static;

  loadData(): Observable<ICultivationDictionaryModel[]> {
    const url = `${this.config.configuration.api_url}/definitions/cultivations`;
    return this.httpClient.get<ICultivationDictionaryModel[]>(url);
  }
}
