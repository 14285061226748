import { Injectable } from '@angular/core';
import { AppConfiguration } from './app-configuration';
import { AppConfigurationService } from './app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(private appConfigurationService: AppConfigurationService) {}

  public init(): Promise<void> {
    return fetch('assets/app-config.json')
      .then(response => response.json())
      .then((config: AppConfiguration) => {
        this.appConfigurationService.configuration = config;
      });
  }
}
