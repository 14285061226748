import { Injectable } from '@angular/core';
import { DictionaryType } from '../../dictionary-type.enum';
import { DictionaryMode } from '../../dictionary-mode.enum';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISeedTypeDictionaryModel } from './seed-type-dictionary.model';
import { AppConfigurationService } from '../../../configuration/app-configuration.service';
import { map } from 'rxjs/operators';
import { toCamel } from '../common/json-helper';
import { DictionaryServiceBase } from '../common/dictionary-service-base';
import { DictionaryDropdownItem } from '../../dictionary-dropdown-item.interface';
import { ApiListResponse } from '../../../webapi';

@Injectable()
export class SeedTypeDictionaryService extends DictionaryServiceBase<ISeedTypeDictionaryModel[]> {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigurationService
  ) {
    super();
  }

  type: DictionaryType = DictionaryType.SeedType;
  mode: DictionaryMode = DictionaryMode.Static;

  loadData(): Observable<ISeedTypeDictionaryModel[]> {
    const url = `${this.config.configuration.api_url}/api/v1/seeds?page=1&pageSize=99999&fields=name,type`;

    return this.httpClient
      .get<ApiListResponse<ISeedTypeDictionaryModel>>(url)
      .pipe(map(result => result.items))
      .pipe(map(result => result.map(x => toCamel(x) as ISeedTypeDictionaryModel)))
      .pipe(
        map(result =>
          result.map(x => {
            x.dictionaryType = DictionaryType[DictionaryType.SeedType];
            x.codeName = x.name;
            return x;
          })
        )
      );
  }

  mapItem(item: ISeedTypeDictionaryModel): DictionaryDropdownItem {
    return {
      label: item.name,
      value: JSON.stringify({ rowKey: item.id, name: item.name })
    };
  }
}
