import { NgModule, FactoryProvider, ClassProvider, PLATFORM_ID } from '@angular/core';
import { WindowRef, BrowserWindowRef, WindowService, WindowPrintService, WINDOW, WindowScrollService } from './window.service';
import { isPlatformBrowser } from '@angular/common';

/* Create an factory function that returns the native window object. */
export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: object): Window | object {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }
  return new Object();
}

/* Create an injectable provider that uses the windowFactory function for returning the native window object. */
const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowRef, PLATFORM_ID]
};

/* Create a injectable provider for the WindowRef token that uses the BrowserWindowRef class. */
const browserWindowProvider: ClassProvider = {
  provide: WindowRef,
  useClass: BrowserWindowRef
};

@NgModule({
  providers: [browserWindowProvider, windowProvider, WindowPrintService, WindowService, WindowScrollService]
})
export class CommonModule {}
