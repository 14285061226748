import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictionariesService, DICTIONARIES_SERVICES } from './dictionaries.service';
import { ConfigurationModule } from '../configuration/configuration.module';
import { ActiveSubstanceTypeDictionaryService } from './definitions/active-substance-type/active-substance-type-dictionary.service';
import { AgrofagTypeDictionaryService } from './definitions/agrofag-type/agrofag-type-dictionary.service';
import { FertilizerTypeDictionaryService } from './definitions/fertilizer-type/fertilizer-type-dictionary.service';
import { CultivationCropDictionaryService } from './definitions/cultivation/cultivation-crop-dictionary.service';
import { CultivationInterCropDictionaryService } from './definitions/cultivation/cultivation-inter-crop-dictionary.service';
import { MachineTypeDictionaryService } from './definitions/machine-type/machine-type-dictionary.service';
import { PesticideTypeDictionaryService } from './definitions/pesticide-type/pesticide-type-dictionary.service';
import { SeedTypeDictionaryService } from './definitions/seed-type/seed-type-dictionary.service';
import { TreatmentTypeDictionaryService } from './definitions/treatment-type/treatment-type-dictionary.service';
import { BuildingTypeDictionaryService } from './definitions/building-type/building-type-dictionary.service';

const ACTIVE_SUBSTANCE_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: ActiveSubstanceTypeDictionaryService
};
const AGROFAG_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: AgrofagTypeDictionaryService
};
const CULTIVATION_CROP_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: CultivationCropDictionaryService
};
const CULTIVATION_INTER_CROP_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: CultivationInterCropDictionaryService
};
const FERTILIZER_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: FertilizerTypeDictionaryService
};
const MACHINE_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: MachineTypeDictionaryService
};
const PESTICIDE_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: PesticideTypeDictionaryService
};
const SEED_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: SeedTypeDictionaryService
};
const TREATMENT_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: TreatmentTypeDictionaryService
};
const BUILDING_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: BuildingTypeDictionaryService
};

@NgModule({
  declarations: [],
  imports: [CommonModule, ConfigurationModule],
  providers: [
    DictionariesService,
    PesticideTypeDictionaryService,
    ACTIVE_SUBSTANCE_TYPE_DICT,
    AGROFAG_TYPE_DICT,
    CULTIVATION_CROP_DICT,
    CULTIVATION_INTER_CROP_DICT,
    FERTILIZER_TYPE_DICT,
    MACHINE_TYPE_DICT,
    PESTICIDE_TYPE_DICT,
    SEED_TYPE_DICT,
    TREATMENT_TYPE_DICT,
    BUILDING_TYPE_DICT
  ]
})
export class DictionariesModule {}
