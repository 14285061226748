import { FormControl, FormGroup } from '@angular/forms';

export function getFormControl(formGroup: FormGroup, path: string): FormControl {
  const control = formGroup.get(path);
  if (control === null) {
    throw new Error(`Unable to find control ${path} in ${formGroup}`);
  }

  if (!(control instanceof FormControl)) {
    throw new Error(`Expected control to be instance of FormControl but received ${typeof control}`);
  }

  return control as FormControl;
}
