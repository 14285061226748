import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { scrollToInvalidInput } from '@farm-portal/shared/functions/scroll-to-invalid-input';

@Component({
  selector: 'lib-form',
  templateUrl: './form.component.html'
})
export class FormComponent implements OnInit {
  @Input() public headerTranslationKey: string;
  @Input() public formGroup: FormGroup;
  @Input() public isEditMode: boolean | null;
  @Input() public isSubmitDisabled = false;

  @Output() public navigateBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() public formSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() public editModeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private initialEditMode: boolean | null;
  private scrollToInvalidInputTimeout: NodeJS.Timeout | null;

  constructor(@Inject(DOCUMENT) private document: Document) {}
  public ngOnInit() {
    this.initialEditMode = this.isEditMode;
  }

  public isEditVisible() {
    if (this.isEditMode === null || this.isEditMode === undefined) {
      return false;
    }

    return !this.isEditMode;
  }

  public fieldsEnabled() {
    if (this.isEditMode === null || this.isEditMode === undefined) {
      return true;
    }

    return this.isEditMode;
  }

  public onEditClick() {
    this.isEditMode = !this.isEditMode;
    this.editModeChanged.emit(this.isEditMode);
  }

  public onCancel() {
    if (this.initialEditMode) {
      this.navigateBack.emit();
      return;
    }

    if (this.isEditMode) {
      this.isEditMode = false;
      this.editModeChanged.emit(this.isEditMode);
      return;
    }

    this.navigateBack.emit();
  }

  public onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      this.setScrollToInvalidInputTimeout();
      return;
    }
    this.formSubmitted.next();
  }

  private setScrollToInvalidInputTimeout() {
    if (this.scrollToInvalidInputTimeout !== null) {
      clearTimeout(this.scrollToInvalidInputTimeout);
      this.scrollToInvalidInputTimeout = null;
    }
    this.scrollToInvalidInputTimeout = scrollToInvalidInput(this.document);
  }
}
