import { Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'messageDatePipe'
})
export class MessageDatePipe extends DatePipe {
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;

  transform(value: Date | string | number): string | null {
    const date = super.transform(value, 'mediumDate');
    const hour = super.transform(value, 'shortTime');
    return `${date} • ${hour}`;
  }
}
