import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberUtilService {
  private static readonly TWO = 100;
  private static readonly THREE = 1000;
  private static readonly FOUR = 10000;
  private static readonly FIVE = 100000;

  round2(value: number): number {
    return Math.round(value * NumberUtilService.TWO) / NumberUtilService.TWO;
  }

  round3(value: number): number {
    return Math.round(value * NumberUtilService.THREE) / NumberUtilService.THREE;
  }

  round4(value: number): number {
    return Math.round(value * NumberUtilService.FOUR) / NumberUtilService.FOUR;
  }

  round5(value: number): number {
    return Math.round(value * NumberUtilService.FIVE) / NumberUtilService.FIVE;
  }
}
