import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-icon-button',
  templateUrl: './icon-button.component.html'
})
export class IconButtonComponent {
  @Input() icon: string | null = 'ph ph-plus';
  @Input() isVisible = true;
  @Input() titleTranslationKey: string | null = null;
  @Input() disabled = false;
  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public click($event: MouseEvent): void {
    this.onClick.emit($event);
  }
}
