import { Directive, HostListener, Inject } from '@angular/core';

import { SidebarLinkDirective } from './sidebarlink.directive';

@Directive({
  selector: '[appSidebarAnchorToggle]'
})
export class SidebarAnchorToggleDirective {
  constructor(@Inject(SidebarLinkDirective) protected navlink: SidebarLinkDirective) {}

  @HostListener('click')
  onClick() {
    this.navlink.toggle();
  }
}
