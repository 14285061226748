export function scrollToInvalidInput(document: Document) {
  return setTimeout(() => {
    const invalidInput = document.querySelector('.invalid-feedback');
    if (invalidInput) {
      const errorPosition = invalidInput.getBoundingClientRect().top + window.pageYOffset - 150;
      window.scrollTo({
        top: errorPosition,
        behavior: 'smooth'
      });
    }
  }, 200);
}
