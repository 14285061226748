import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  templateUrl: './notFound.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./notFound.component.scss']
})
export class NotFoundComponent {
  constructor(
    private readonly location: Location,
    private readonly router: Router
  ) {}

  public navigateBack() {
    return this.location.back();
  }

  public navigateMainPage() {
    return this.router.navigate(['']);
  }
}
