import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dictionaryTranslateKey'
})
export class DictionaryTranslateKeyPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return `APPLICATION.DICTIONARIES.${value}`;
    }
    return '';
  }
}
