import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigurationService, MessageType } from 'farmcloud-core';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
import { MessageApiListResponse, MessageDto } from '../models/message.dto';
import { RequestListGridify } from '@farm-portal/core/common/request-list-gridify';
import { BaseProxyService } from '@farm-portal/core/common/base-proxy-service';
import { ContinueThreadMessageCommand, CreateMessageCommand } from '@farm-portal/core/message/models/message-command.model';
import { MessageListFilter, MessageListOrder } from '@farm-portal/core/message/models/message-list-filter.model';

@Injectable({
  providedIn: 'root'
})
export class MessageCoreProxyService extends BaseProxyService {
  private readonly baseApiUrl: string;

  constructor(
    config: AppConfigurationService,
    private userContextService: UserContextService,
    private httpClient: HttpClient
  ) {
    super();
    this.baseApiUrl = `${config.configuration.api_url}/api/messages/v1/message`;
  }

  public get(
    requestList: RequestListGridify<MessageListFilter, MessageListOrder>,
    isAlertMode: boolean
  ): Observable<MessageApiListResponse> {
    if (!requestList.filter) {
      requestList.filter = [];
    }

    if (isAlertMode) {
      requestList.filter.push({
        field: 'RecipientOrganizationId',
        value: this.userContextService.currentOrganizationId,
        condition: '='
      });

      requestList.filter.push({
        field: 'Type',
        value: MessageType.Notification,
        condition: '!='
      });
    }

    const params = super.prepareQueryGridifyParams(requestList);
    return this.httpClient.get<MessageApiListResponse>(`${this.baseApiUrl}`, { params });
  }

  public getAllUnreadMessages(): Observable<MessageApiListResponse> {
    const requestList: RequestListGridify<MessageListFilter, MessageListOrder> = {
      filter: [
        {
          field: 'RecipientOrganizationId',
          value: this.userContextService.currentOrganizationId,
          condition: '='
        },
        {
          field: 'IsRead',
          value: false,
          condition: '='
        },
        {
          field: 'Type',
          value: MessageType.Notification,
          condition: '!='
        }
      ],
      page: 1,
      pageSize: 20
    };
    const params = super.prepareQueryGridifyParams(requestList);
    return this.httpClient.get<MessageApiListResponse>(`${this.baseApiUrl}`, { params });
  }

  public getAllNotifications(lang: string): Observable<MessageApiListResponse> {
    const requestList: RequestListGridify<MessageListFilter, MessageListOrder> = {
      filter: [
        {
          field: 'RecipientOrganizationId',
          value: this.userContextService.currentOrganizationId,
          condition: '='
        },
        {
          field: 'ThreadId',
          value: null,
          condition: '='
        },
        {
          field: 'Type',
          value: MessageType.Notification,
          condition: '='
        }
      ],
      orderBy: [
        {
          field: 'CreationDate',
          sortOrder: 'desc'
        }
      ],
      page: 1,
      pageSize: 20
    };

    const params = super.prepareQueryGridifyParams(requestList);
    return this.httpClient.get<MessageApiListResponse>(`${this.baseApiUrl}/${lang}`, { params });
  }

  public createMessage(messageCommand: CreateMessageCommand): Observable<void> {
    return this.httpClient.post<void>(this.baseApiUrl, messageCommand);
  }

  public continueThread(messageCommand: ContinueThreadMessageCommand): Observable<void> {
    const url = `${this.baseApiUrl}` + `/continueThread`;
    return this.httpClient.post<void>(url, messageCommand);
  }

  public markAsRead(id: string): Observable<MessageDto> {
    const url = `${this.baseApiUrl}`;
    const body = { id: `${id}` };
    return this.httpClient.patch<MessageDto>(url, body);
  }

  public markAllAsRead(): Observable<void> {
    const url = `${this.baseApiUrl}/notifications/markAllAsRead`;
    return this.httpClient.patch<void>(url, {});
  }
}
