import { Injectable } from '@angular/core';
import { DictionaryType } from '../../dictionary-type.enum';
import { DictionaryMode } from '../../dictionary-mode.enum';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigurationService } from '../../../configuration/app-configuration.service';
import { map } from 'rxjs/operators';
import { toCamel } from '../common/json-helper';
import { DictionaryServiceBase } from '../common/dictionary-service-base';
import { DictionaryDropdownItem } from '../../dictionary-dropdown-item.interface';
import { IPesticideTypeDictionaryModel } from './pesticide-type-dictionary.model';
import { ApiListResponse } from '../../../webapi';
import { CultivationType } from '../../../enums';

@Injectable()
export class PesticideTypeDictionaryService extends DictionaryServiceBase<IPesticideTypeDictionaryModel[]> {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigurationService
  ) {
    super();
  }

  type: DictionaryType = DictionaryType.PesticideType;
  mode: DictionaryMode = DictionaryMode.Static;

  loadData(): Observable<IPesticideTypeDictionaryModel[]> {
    const url = `${this.getAllUrl}&fields=name,rowKey,activeSubstances,gracePeriods,cultivations,typeOf,productCode`;
    return this.getData(url);
  }

  mapItem(item: IPesticideTypeDictionaryModel): DictionaryDropdownItem {
    return {
      label: item.name,
      value: JSON.stringify({ rowKey: +item.rowKey, name: item.name })
    };
  }

  loadDataByCultivationAndCultivationType(
    cultivationCode: string,
    cultivationTypeCode: string,
    agrofagsInCultivation: string
  ): Observable<IPesticideTypeDictionaryModel[]> {
    if (cultivationCode == null) {
      return of([]);
    }

    const fields = 'name,rowKey,typeOf,activeSubstances,cultivations,gracePeriods,productCode,eko';
    let url = `${this.getAllUrl}&fields=${fields}&filter=cultivations.name,${cultivationCode}`;
    if (this.isEcological(cultivationTypeCode)) {
      url += ';eko,true';
    }

    if (agrofagsInCultivation !== null || agrofagsInCultivation !== undefined) {
      url += `;cultivations.agrofagsInCultivation,${agrofagsInCultivation}`;
    }

    return this.getData(url);
  }

  private isEcological(cultivationTypeCode: string): boolean {
    return [CultivationType.Agroecological, CultivationType.Ecological].some(x => x === cultivationTypeCode);
  }

  private getData(url: string): Observable<IPesticideTypeDictionaryModel[]> {
    return this.httpClient
      .get<ApiListResponse<IPesticideTypeDictionaryModel>>(url)
      .pipe(map(result => result.items))
      .pipe(map(result => result.map(x => toCamel(x) as IPesticideTypeDictionaryModel)))
      .pipe(
        map(result =>
          result.map(x => {
            x.dictionaryType = DictionaryType[DictionaryType.PesticideType];
            x.codeName = x.name;
            return x;
          })
        )
      );
  }

  private get getAllUrl(): string {
    return `${this.config.configuration.api_url}/api/v1/pesticides?page=1&pageSize=999999`;
  }
}
